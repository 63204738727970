import React, {useEffect} from "react";
import StickyDefault from "../layout/stickyDefault";
import {Link} from "react-router-dom";

function VoteList() {

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="voteList">
                    <div className="box">
                        <div className="top">
                            <ul className="info">
                                <li className="date">24.06.14 ~ 24.06.30</li>
                                <li className="title">트렌드세터 연예인 랭킹</li>
                            </ul>
                            <ul className="mask"></ul>
                            <ul className="bg"><img src="https://d2k5miyk6y5zf0.cloudfront.net/article/MYH/20240702/MYH20240702024800641.jpg" alt=""/></ul>
                        </div>
                        <div className="list">
                            <ul>
                                <li className="rank">
                                    <div className="num">1</div>
                                    <div className="rankMark up">2</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">뉴진스</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">2</div>
                                    <div className="rankMark same">0</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">에스파</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">3</div>
                                    <div className="rankMark down">3</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://i.namu.wiki/i/dbYaykrNkUTz5RZOfjwQjU38vmA6zORYe_H60qH17Sl_PJ-oXcqvGPyuyJu6HKcDJuMqQdq--y6JPabC0QZzDA.webp" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">IVE</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">4</div>
                                    <div className="rankMark new">new</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://phinf.wevpstatic.net/MjAyMzEwMTNfOTcg/MDAxNjk3MTc4NTMyOTA2.YcfBd9JEgr4AG6ae5CxXQlHFZoyz0Gsbsd28r9gViZsg.ufxCBuSFybk-1d8KlB7a3WHGoQNB-JnN_OlsJC2nmUcg.JPEG/bfb0f355-9e7d-4c83-a030-814c6fe53c7b.jpeg?type=w670" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">QWER</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">5</div>
                                    <div className="rankMark up">100</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://i.namu.wiki/i/KjP897v1S9ZJsJcWefRGWNXEpZpmqjeAwP-VQOUA8cZRe8lSlfW6_gPoobenfltRC0Oa92dEN61t42prA50CbA.webp" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">아일릿</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">6</div>
                                    <div className="rankMark up">12</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://nolae.eu/cdn/shop/articles/gi-dle-beginnt-2024-mit-dem-zweiten-studio-album-2-889229_1200x732.jpg?v=1704927358" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">G-IDLE</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">7</div>
                                    <div className="rankMark same">0</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202306/04/138bdfca-3e86-4c09-9632-d22df52a0484.jpg" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">가수<div className="dot"></div>여성</dt>
                                        <dt className="name">아이유</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">8</div>
                                    <div className="rankMark up">24</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://i.namu.wiki/i/AF9SpVUNbbSFcOjRXIsyr29SxlZqhDX2Ui_dP04_VUYV98bC0t4bwkOs3Ec5FgNn6UcysEatLP5OYtsXTOzlkQ.webp" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">가수<div className="dot"></div>남성</dt>
                                        <dt className="name">지코</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">9</div>
                                    <div className="rankMark down">4</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://img1.newsis.com/2023/01/06/NISI20230106_0001170846_web.jpg" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">르세라핌</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                            <ul>
                                <li className="rank">
                                    <div className="num">10</div>
                                    <div className="rankMark up">100</div>
                                </li>
                                <li className="info">
                                    <dl className="thumb"><img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2022/11/29/479c1886-acb7-4416-963b-24baf1888c78.jpg" alt=""/></dl>
                                    <dl className="txt">
                                        <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                        <dt className="name">카라</dt>
                                        <dt className="scoreIn">4,669 점</dt>
                                    </dl>
                                </li>
                                <li className="score">4,669 점</li>
                                <Link to="/vote/do"><li className="btn">투표</li></Link>
                            </ul>
                        </div>
                    </div>
                    <Link to="/vote/another"><div className="another">선호하는 대상이 없나요?</div></Link>
                </div>

            </section>
        </>
    );
}

export default VoteList;