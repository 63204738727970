const menu = [
    {
        title: '뉴스',
        pathName: '/news',
        link: '/news/trend',
        subMenu: [
            {
                title: '트렌드 뉴스',
                pathName: '/news/trend',
                link: '/news/trend'
            },
            {
                title: '랭킹뉴스',
                pathName: '/news/ranking',
                link: '/news/ranking'
            },
            {
                title: '디시이슈',
                pathName: '/news/issue',
                link: '/news/issue/1'
            },
            {
                title: '디시인터뷰',
                pathName: '/news/interview',
                link: '/news/interview/1'
            },
        ],
    },
    {
        title: '랭킹',
        pathName: '/ranking',
        link: '/ranking/all',
        subMenu: [
            {
                title: '전체',
                pathName: '/ranking/all',
                link: '/ranking/all'
            },
            {
                title: '연예인',
                pathName: '/ranking/people/celebrity',
                link: '/ranking/people/celebrity'
            },
            {
                title: '스포츠인',
                pathName: '/ranking/people/athlete',
                link: '/ranking/people/athlete'
            },
            {
                title: '유튜버',
                pathName: '/ranking/people/youtuber',
                link: '/ranking/people/youtuber'
            }
        ],
    },
    {
        title: '투표',
        pathName: '/vote',
        link: '/vote/all',
        subMenu: [
            {
                title: '투표모아보기',
                pathName: '/vote/all',
                link: '/vote/all'
            },
            {
                title: '트렌드 어워즈',
                pathName: '/vote/awards',
                link: '/vote/awards'
            }
        ],
    },
];

export default menu;
