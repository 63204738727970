import React, { createContext, useState, useContext, useEffect } from 'react';
import { useApi } from '../js/module/api';

// Context 생성
const Context = createContext();

// Custom Hook
export const useAppContext = () => useContext(Context);

export const ContextProvider = ({ children }) => {

    const naverClientID = "GpUAdIW8TuvaJvTfRJXv";
    const naverClientSecret = "CkTLR10jvm";
    const naverCallbackUrl = "https://dctrend.rankify.best/login";
    const kakaoApiKey = "91080ec081f09b8cf1408c71fafe76db";
    const googleClientId = "766665631250-s4t4vfjf85vc1j4e2o515v4rnomcqe5m.apps.googleusercontent.com";

    const [menuOpen, setMenuOpen] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const { apiRequest } = useApi();
    const apiUrl = process.env.REACT_APP_API_URL; // 환경 변수에서 API URL 가져오기

    const openMenu = () => setMenuOpen(true);
    const closeMenu = () => setMenuOpen(false);
    const toggleMenu = () => setMenuOpen(prev => !prev);

    // 메뉴 상태에 따라 body 스크롤 비활성화
    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [menuOpen]);

    // 카카오 API 키를 세션 스토리지에 저장
    useEffect(() => {
        if (kakaoApiKey) {
            sessionStorage.setItem('kakaoApiKey', kakaoApiKey);
        }
    }, [kakaoApiKey]);

    useEffect(() => {
        const categoryLoad = async () => {
            try {
                const data = await apiRequest(apiUrl + 'api/v1/news/categories', {}, 'GET');
                localStorage.setItem('categoryList', JSON.stringify(data.data)); // 데이터를 로컬스토리지에 저장
                setCategoryList(data.data); // 상태 업데이트
            } catch (error) {
                console.error("Failed to fetch news categories:", error);
            }
        };

        categoryLoad().catch(error => console.error("Failed to load categories:", error)); // API 호출 및 데이터 설정

    }, [apiRequest, apiUrl]);


    return (
        <Context.Provider value={{
            menuOpen,
            openMenu,
            closeMenu,
            toggleMenu,
            categoryList,
            naverClientID,
            naverClientSecret,
            naverCallbackUrl,
            kakaoApiKey,
            googleClientId
        }}>
            {children}
        </Context.Provider>
    );
};