import StickyDefault from "../layout/stickyDefault";
import React, {useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Link} from "react-router-dom";
import SortSelect from "../../component/sortSelect";
import {Pagination} from "antd";

function Search() {

    const [selectedSort, setSelectedSort] = useState('latest');

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="searchResult">
                    <div className="resultTitle"><span>'정동원'</span>검색결과</div>

                    <div className="profileTitle">인물정보</div>
                    <div className="profile">
                        <ul className="thumb"><img src="https://cdnimg.melon.co.kr/cm2/artistcrop/images/027/49/421/2749421_20230901120004_500.jpg?42d681ad16ae401f9de5307dab779022/melon/resize/416/quality/80/optimize" alt=""/></ul>
                        <ul className="info">
                            <div className="name">정동원</div>
                            <li>
                                <dl>국적</dl>
                                <dl>대한민국</dl>
                            </li>
                            <li>
                                <dl>활동유형</dl>
                                <dl>남성, 솔로</dl>
                            </li>
                            <li>
                                <dl>활동년대</dl>
                                <dl>2010, 2020</dl>
                            </li>
                            <li>
                                <dl>활동장르</dl>
                                <dl>성인가요/트로트, 국내드라마, 발라드</dl>
                            </li>
                            <li>
                                <dl>데뷔(곡)</dl>
                                <dl>2008.09.18 미아</dl>
                            </li>
                            <li>
                                <dl>생일</dl>
                                <dl>1993.05.16</dl>
                            </li>
                            <div className="source">출처 : 멜론(Melon) www.melon.com/artist</div>
                        </ul>
                    </div>
                    <div className="another">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={16}
                        >
                            <SwiperSlide>
                                <ul>
                                    <li className="thumb"><img src="https://search.pstatic.net/common?type=f&size=190x254&quality=100&direct=true&src=http%3A%2F%2Fsstatic.naver.net%2Fpeople%2F32%2F20220419154414531.gif" alt=""/></li>
                                    <li>
                                        <dl>가수</dl>
                                        <dl>정동원</dl>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li className="thumb"><img src="https://search.pstatic.net/common?type=f&size=190x254&quality=100&direct=true&src=http%3A%2F%2Fsstatic.naver.net%2Fpeople%2FprofileImg%2F999d81a9-3d3d-45f2-b1d6-1573ed06d6d1.png" alt=""/></li>
                                    <li>
                                        <dl>스포츠인</dl>
                                        <dl>정동원</dl>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li className="thumb"><img src="https://search.pstatic.net/common?type=b&size=144&expire=1&refresh=true&quality=100&direct=true&src=http%3A%2F%2Fsstatic.naver.net%2Fpeople%2FprofileImg%2F0f281fbb-7478-4d58-b4e6-814ca5a56f03.jpg" alt=""/></li>
                                    <li>
                                        <dl>배우</dl>
                                        <dl>정동원</dl>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li className="thumb"><img src="https://cdn.trend.rankify.best/dctrend/front/images/profile_default.svg" alt=""/></li>
                                    <li>
                                        <dl>가수</dl>
                                        <dl>정동원</dl>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li className="thumb"><img src="https://cdn.trend.rankify.best/dctrend/front/images/profile_default.svg" alt=""/></li>
                                    <li>
                                        <dl>배우</dl>
                                        <dl>정동원</dl>
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="relationVote">
                        <div className="tit">관련 투표<span>4</span></div>
                        <div className="list">
                            <Swiper
                                direction='vertical'
                                slidesPerView='auto'
                                spaceBetween={0}
                                noSwiping={true}
                                noSwipingClass='swiper-slide'
                                breakpoints={{
                                    480: {
                                        direction: 'horizontal',
                                        slidesPerView: 'auto',
                                        spaceBetween: 16,
                                        noSwiping: false,
                                        noSwipingClass: 'swiper-slide',
                                    },
                                    1200: {
                                        direction: 'horizontal',
                                        slidesPerView: 3,
                                        spaceBetween: 16,
                                        noSwiping: true,
                                        noSwipingClass: 'swiper-slide',
                                    }
                                }}
                            >
                                <SwiperSlide>
                                    <ul className="hoverImgPt">
                                        <li className="thumb"><img src="https://cdn.hankyung.com/photo/202404/01.30922082.1.jpg" alt=""/></li>
                                        <li className="info">
                                            <dl className="date">23.06.14 ~ 24.06.30</dl>
                                            <dl className="title">트로트 가수 남성 랭킹</dl>
                                            <dl className="btn">투표</dl>
                                        </li>
                                    </ul>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ul className="hoverImgPt">
                                        <li className="thumb"><img src="https://img.sbs.co.kr/newsnet/etv/upload/2022/08/04/30000781252_1280.jpg" alt=""/></li>
                                        <li className="info">
                                            <dl className="date">23.06.14 ~ 24.06.30</dl>
                                            <dl className="title">국힙원탑 순위</dl>
                                            <dl className="btn">투표</dl>
                                        </li>
                                    </ul>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ul className="hoverImgPt">
                                        <li className="thumb"><img src="https://i.namu.wiki/i/DCCH_4-GbeOYTnw-LZztGIqIM1CcH5cSM0QSEC4Z05_bZxkN8muypFUlSA3YGis_EQpmjd-7WhG0Zbu8GQxfzA.webp" alt=""/></li>
                                        <li className="info">
                                            <dl className="date">23.06.14 ~ 24.06.30</dl>
                                            <dl className="title">솔로 가수 랭킹</dl>
                                            <dl className="btn">투표</dl>
                                        </li>
                                    </ul>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="more"><Link to="/vote/relation"><div className="moreBtn">연관 투표 전체보기<div className="arw s16"></div></div></Link></div>
                    </div>

                    <div className="relationNews">
                        <div className="newsTitle">관련뉴스</div>
                        <SortSelect
                            options={[
                                { id: 'latest', label: '최신순' },
                                { id: 'views', label: '조회수순' },
                            ]}
                            selectedSort={selectedSort}
                            onSortChange={setSelectedSort}
                        />
                        <div className="tab">
                            <ul className="on">
                                <li>전체</li>
                                <li>1,060</li>
                            </ul>
                            <ul>
                                <li>트렌드 뉴스</li>
                                <li>1,023</li>
                            </ul>
                            <ul>
                                <li>디시이슈</li>
                                <li>37</li>
                            </ul>
                            <ul>
                                <li>디시인터뷰</li>
                                <li>0</li>
                            </ul>
                        </div>
                        <div className="newsList">
                            <div className="list full">
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://img.sportsworldi.com/content/image/2024/07/09/20240709503590.jpg" alt=""/></div>
                                        <li className="tit">활동 중단했던 리아 복귀…있지, 하반기 완전체 앨범 발매활동 중단했던 리아 복귀…있지, 하반기 완전체 앨범 발매활동 중단했던 리아 복귀…있지, 하반기 완전체 앨범 발매</li>
                                        <li className="txt">JYP엔터테인먼트는 지난 8일 오후 공식 SNS 채널을 통해 ITZY 리아의 복귀 소식을 알렸다. 이에 따르면 리아는 하반기 발매 예정인 ITZY의 새 앨범을 시작으로 본격적인 활동에 임할 예정이다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071816474659725_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">'여장한' 조정석, 女 속옷 입고 하이힐 질주.."배우의 숙명이죠"(파일럿)[★FULL인터뷰]</li>
                                        <li className="txt">배우 조정석이 '파격 변신'을 앞세워 코미디의 '정석'을 보여줬다. 영화 '파일럿'을 통해 원톱 주연으로 나선 조정석에게는 부담감도, 어려움도 없었다. '역시'라는 감탄이 절로 나올 정도로 자신의 진가를 발휘하는 조정석이다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg" alt=""/></div>
                                        <li className="tit">허영지 "카라 기존 멤버와 거리감..컴백 준비하며 가까워져"</li>
                                        <li className="txt">19일 유튜브 메리고라운드 채널에는 '김종민의 면데이뚜 데면데면'(연출 조송이, 강재은, 이하 '데면데면')에 출연한 허영지의 영상이 공개된다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071911032637162_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">육성재, '귀궁' 출연 [공식]</li>
                                        <li className="txt">육성재는 팔척귀의 농간으로 혼란에 빠진 궁을 지키고자 하는 검서관이며 무녀 여리의 첫사랑인 윤갑 역을 맡았다. 윤갑은 여리의 몸을 빌어 승천하고 싶어하던 악신 이무기에게 몸을 빼앗기는 얄궂은 운명의 소유자다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071911222993896_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">스트레이 키즈 "JYP 전원 재계약, 멤버들과 한마음 한뜻 모아"</li>
                                        <li className="txt">보이 그룹 스트레이 키즈(Stray Kids)가 소속사 JYP엔터테인먼트와 재계약을 하게 된 이유를 설명했다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071910081981871_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">블랙핑크 지수 'ME' 유튜브뮤직 10억 스트리밍 '대기록'</li>
                                        <li className="txt">지수 첫 싱글 앨범 '미(ME)'가 글로벌 음악 앱 유튜브 뮤직(YouTube Music)에서 10억 스트리밍을 돌파했다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071907222123295_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">임영웅, 아이돌차트 평점랭킹 173주 연속 1위</li>
                                        <li className="txt">임영웅은 지난 7월 12일부터 7월 18일까지 집계된 7월 2주차 아이돌차트 평점랭킹에서 40만 2007표를 획득, 1위에 올랐다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071907143948034_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">방탄소년단 진, 마이원픽 K팝 개인부문 20주 연속 1위</li>
                                        <li className="txt">진은 마이원픽(MY1PICK) 'K-POP 개인 부문' 7월 2주차(7월 8일~7월 14일) 투표에서 609만 5737개의 하트를 얻어 압도적인 1위에 올랐다.이로써 진은 2월 5주차부터 7월 2주차까지 20주 연속 주간랭킹 1위를 달성했다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071808151415320_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">르세라핌 'Perfect Night', 스포티파이 누적 3억 돌파..'자체 최단新'</li>
                                        <li className="txt">세계 최대 음악 스트리밍 플랫폼 스포티파이에 따르면, 르세라핌(김채원, 사쿠라, 허윤진, 카즈하, 홍은채)의 첫 영어 디지털 싱글 'Perfect Night'가 7월 15일 기준 3억 9만 2627회 재생됐다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                                <ul className="hoverImgPt">
                                    <Link to="/news/trend/detail/14/448914">
                                        <div className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/07/2024071807033828494_1.jpg/dims/optimize/" alt=""/></div>
                                        <li className="tit">카라, 싱글 'I Do I Do' 5인 5색 콘셉트 포토 공개</li>
                                        <li className="txt">카라(박규리, 한승연, 니콜, 강지영, 허영지)는 지난 17일 공식 SNS에 디지털 싱글 'I Do I Do'의 'Blue Wave' 버전 단체 콘셉트 포토를 게재했다.</li>
                                        <li className="info">스포츠월드</li>
                                    </Link>
                                </ul>
                            </div>
                            <div className="paging">
                                <Pagination
                                    align="center"
                                    defaultCurrent="1"
                                    total="200"
                                    pageSize="12"
                                    showSizeChanger={false}
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Search;