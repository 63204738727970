import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../component/LoadingSpinner';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

let root;

export function useApi() {
    const [loading, setLoading] = useState(false);
    const [fingerprint, setFingerprint] = useState(null);

    // 핑거프린트 생성 및 초기화
    useEffect(() => {
        const getFingerprint = async () => {
            const storedFingerprint = localStorage.getItem('fingerprint');

            if (storedFingerprint) {
                setFingerprint(storedFingerprint);
            } else {
                const fp = await FingerprintJS.load();
                const result = await fp.get();
                const visitorId = result.visitorId;

                localStorage.setItem('fingerprint', visitorId);
                setFingerprint(visitorId);
            }
        };

        getFingerprint().catch(error => console.error("Failed to get fingerprint:", error));
    }, []);

    const showLoadingSpinner = () => {
        const loaderContainer = document.createElement('div');
        loaderContainer.id = 'loader-container';
        document.body.appendChild(loaderContainer);

        if (!root) {
            root = ReactDOM.createRoot(loaderContainer);
        }

        root.render(<LoadingSpinner />);
    };

    const hideLoadingSpinner = () => {
        const loaderContainer = document.getElementById('loader-container');
        if (loaderContainer) {
            if (root) {
                root.unmount();
                document.body.removeChild(loaderContainer);
                root = null;
            }
        }
    };

    const apiRequest = useCallback(async (url, params = {}, method = 'POST', externalHeaders = {}, headersMode = 'merge', showError = true, showLoading = true) => {
        setLoading(true);
        if (showLoading) {
            showLoadingSpinner();
        }

        const defaultHeaders = {
            'Content-Type': method === 'GET' ? undefined : 'application/json',
            'User-Identifier': fingerprint,  // 핑거프린트를 헤더에 추가
        };

        const finalHeaders = headersMode === 'merge'
            ? { ...defaultHeaders, ...externalHeaders }
            : { ...externalHeaders };

        let body;

        if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
            if (params instanceof FormData) {
                body = params;
            } else {
                body = JSON.stringify(params);
            }
        }

        try {
            const response = await fetch(url, {
                method: method,
                headers: finalHeaders,
                body: body,
            });
            if (!response.ok) {
                const error = new Error(`HTTP error! status: ${response.status}`);
                error.status = response.status; // 에러 객체에 상태 코드를 추가
                throw error;
            }

            const data = await response.json();
            return data;
        } catch (error) {
            if (showError) {
                Swal.fire({
                    icon: 'error',
                    text: error.message,
                }).then();
            }
            throw error;
        } finally {
            setLoading(false);
            if (showLoading) {
                hideLoadingSpinner();
            }
        }
    }, [fingerprint]);

    return { apiRequest, loading };
}
