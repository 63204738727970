import React, { useState } from 'react';
import ReactDOM from 'react-dom';

function CommentModify({ isOpen, onClose, isReset }) {
    const [showPasswordInput, setShowPasswordInput] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [commentText, setCommentText] = useState('어제 경기 봤는데 진짜 열심히 하시고 홈런 장면도 너무 멋있었습니다. 비록 결과는 조금 아쉬울 수 있지만 선수와 팀들의 노력이 정말 멋있습니다. 항상 응원합니다!!!');
    const maxLength = 500;

    if (!isOpen) return null;

    const handleConfirm = () => {
        setShowPasswordInput(false);
        setShowEdit(true);
    };

    const handleCancel = () => {
        setShowPasswordInput(true);
        setShowEdit(false);
        onClose();
    };

    const handleChange = (e) => {
        const newText = e.target.value;
        if (newText.length <= maxLength) {
            setCommentText(newText);
        }
    };

    const handleModifyConfirm = (e) => {
        isReset();
    };

    return ReactDOM.createPortal(
        <section className="layerDefault commentModify">
            <div className="inbox">
                <div className="top">댓글 수정</div>
                <div className="close" onClick={handleCancel}>
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" />
                </div>
                {showPasswordInput && (
                    <div className="cont">
                        <div className="commentPassword">
                            <ul className="tit">작성자만 글을 수정할 수 있습니다.</ul>
                            <ul className="txt">
                                글 작성시 입력한 비밀번호를 입력하여<br />글을 수정할 수 있습니다.
                            </ul>
                            <ul className="inp">
                                <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_password.svg" alt="Password" />
                                <input name="password" type="password" placeholder="비밀번호" />
                            </ul>
                        </div>
                    </div>
                )}
                {showEdit && (
                    <div className="cont">
                        <div className="commentModifyIn">
                            <textarea
                                placeholder={`타인의 권리를 침해하거나 비하하는 댓글은 허용되지 않으며, 위반 시 삭제 및 제재될 수 있습니다.\n건전한 토론 문화를 위해 협조 부탁드립니다`}
                                value={commentText}
                                onChange={handleChange}
                                name="comment"
                            >
                                {commentText}
                            </textarea>
                            <div className="limit">
                                {commentText.length}/{maxLength}
                            </div>
                        </div>
                    </div>
                )}
                <div className="bot">
                    <ul className="cancel" onClick={handleCancel}>취소</ul>
                    {showPasswordInput && (
                        <ul className="confirm" onClick={handleConfirm}>확인</ul>
                    )}
                    {showEdit && (
                        <ul className="confirm" onClick={handleModifyConfirm}>수정하기</ul>
                    )}
                </div>
            </div>
        </section>,
        document.body
    );
}

export default CommentModify;
