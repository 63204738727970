import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import KakaoLogin from "react-kakao-login";
import useLoginHandler from "./LoginHandler";
import { useAppContext  } from '../../component/context';
import { useApi } from '../../js/module/api';

function LoginPage() {
    const { naverClientID,naverCallbackUrl,kakaoApiKey } = useAppContext();
    const location = useLocation();
    const naverLoginRef = useRef(null);
    const kakaoLoginRef = useRef(null);
    const [loginData, setLoginData] = useState(null);
    const from = location.state?.from?.pathname || "/";
    const { handleLogin } = useLoginHandler();
    const { apiRequest } = useApi();

    useEffect(() => {
        if (loginData) {
            const { user, from } = loginData;
            handleLogin(user, from);
            setLoginData(null); // 로그인 처리 후 상태 초기화
        }
    }, [loginData, handleLogin]);

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const accessToken = tokenResponse.access_token;

            try {
                const data = await apiRequest(
                    'https://www.googleapis.com/oauth2/v3/userinfo',
                    {}, // params: None needed for this GET request
                    'GET', // method
                    {
                        'Authorization': `Bearer ${accessToken}`
                    },
                    'replace'
                );

                const user = {
                    accessToken: accessToken,
                    type: "google",
                    name: data.name,
                    email: data.email,
                };

                console.log(data);
                setLoginData({ user, from });
            } catch (error) {
                console.error('Error fetching:', error);
            }
        },
        onError: (error) => {
            console.error('Google login error:', error);
        }
    });

    useEffect(() => {
        const { naver } = window;

        const naverLogin = new naver.LoginWithNaverId({
            clientId: naverClientID,  // 네이버에서 발급받은 Client ID
            callbackUrl: naverCallbackUrl,  // 네이버에서 설정한 Redirect URI
            isPopup: false,  // 팝업창으로 로그인할지 여부
            loginButton: { color: 'green', type: 3, height: 56,}  // 로그인 버튼 스타일
        });

        naverLogin.init();

        // 네이버 로그인 인증 후 콜백
        naverLogin.getLoginStatus((status) => {
            if (status) {
                const hash = location.hash.substring(1);
                const params = new URLSearchParams(hash);
                const token = params.get('access_token');
                const { email, name, id } = naverLogin.user;
                const user = {
                    accessToken: token,
                    type: "naver",
                    typeId: id,
                    name: name,
                    email: email,
                };
                console.log(user)
                setLoginData({ user, from });
            }
        });
    }, [from,location.hash,naverClientID,naverCallbackUrl]);

    const handleNaverLoginClick = () => {
        if (naverLoginRef.current) {
            naverLoginRef.current.children[0].click(); // 숨겨진 네이버 로그인 버튼 클릭 트리거
        }
    };

    const kakaoClientId = kakaoApiKey;
    const kakaoOnSuccess = async (data)=>{
        const user = {
            accessToken: data.response.access_token,
            type: "kakao",
            typeId: data.profile.id,
            name: data.profile.kakao_account.name,
            email: data.profile.kakao_account.email,
        };
        console.log(user)
        setLoginData({ user, from });
    }
    const kakaoOnFailure = (error) => {
        console.log(error);
    };

    const handleKakaoLoginClick = () => {
        if (kakaoLoginRef.current) {
            kakaoLoginRef.current.children[0].click(); // 숨겨진 네이버 로그인 버튼 클릭 트리거
        }
    };

    return (
        <section className="loginPage">
            <div className="box">
                <div className="logo"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" /></div>
                <div className="title">간편하게 로그인하고<br />선호하는 분야에 투표해 보세요</div>
                <div className="btn">
                    <ul id="kakaoIdLogin" ref={kakaoLoginRef} style={{display:'none'}}>
                        <KakaoLogin
                            token={kakaoClientId}
                            onSuccess={kakaoOnSuccess}
                            onFail={kakaoOnFailure}
                        />
                    </ul>
                    <ul onClick={handleKakaoLoginClick}><img src="https://cdn.trend.rankify.best/dctrend/front/images/login_btn_kakao.svg" alt="" /></ul>
                    <ul onClick={handleNaverLoginClick}><img src="https://cdn.trend.rankify.best/dctrend/front/images/login_btn_naver.svg" alt="" /></ul>
                    <div id="naverIdLogin" ref={naverLoginRef} style={{ display: 'none' }} />
                    <ul onClick={() => login()}> <img src="https://cdn.trend.rankify.best/dctrend/front/images/login_btn_google.svg" alt="" /></ul>
                </div>
                <div className="info">
                    <ul>디시트렌드의 소셜로그인은 투표권 구매 및 투표권 관리만을 위해 사용됩니다.</ul>
                    <ul>소셜로그인 시, 디시트렌드의 <Link to="/policy">서비스 이용약관</Link> 및 <Link to="/policy/privacy">개인정보 처리방침</Link>에 동의한 것으로 간주합니다.</ul>
                </div>
            </div>
        </section>
    );
}

export default LoginPage;
