// src/component/PrivateRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from '../atoms/authAtom';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useRecoilValue(authState);
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default PrivateRoute;
