import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {authState} from "../../atoms/authAtom";
import {Link, useLocation, useNavigate} from "react-router-dom";

function Footer() {
    const auth = useRecoilValue(authState); // 현재 인증 상태를 가져옵니다.
    const setAuthState = useSetRecoilState(authState); // 인증 상태를 업데이트할 수 있는 함수입니다.
    const navigate = useNavigate(); // 페이지 이동을 위한 navigate 훅입니다.
    const location = useLocation();

    const handleLogout = () => {
        sessionStorage.removeItem('authToken'); // 로컬 스토리지에서 토큰 삭제
        setAuthState({ isAuthenticated: false, token: null, user: null }); // Recoil 상태를 업데이트
        navigate('/'); // 홈 페이지로 이동
    };

    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [showMoveTop, setShowMoveTop] = useState(false); // "move to top" 버튼 상태 추가
    const [moveTopBottom, setMoveTopBottom] = useState(80); // "move to top" 버튼 bottom 위치 상태 추가
    const menuRef = useRef(null);

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY) {
            setIsVisible(false);
            setMoveTopBottom(20); // 스크롤이 내려갈 때 bottom: 20px
        } else {
            setIsVisible(true);
            setMoveTopBottom(80); // 스크롤이 올라갈 때 bottom: 80px
        }

        setLastScrollY(currentScrollY);

        // "move to top" 버튼 보이기/숨기기
        if (currentScrollY > 150) {
            setShowMoveTop(true);
        } else {
            setShowMoveTop(false);
        }
    }, [lastScrollY]);

    const handleMoveTopClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <>
            <div
                className="botMenu"
                ref={menuRef}
                style={{
                    bottom: isVisible ? '0' : '-66px', // 메뉴가 사라질 때 아래로 이동
                }}
            >
                <div className="inBox">
                    <ul className={isActive("/news") ? "on" : ""}>
                        <Link to="/news/trend">
                            <li></li>
                            <li>뉴스</li>
                        </Link>
                    </ul>
                    <ul className={isActive("/ranking") ? "on" : ""}>
                        <Link to="/ranking/all">
                            <li></li>
                            <li>랭킹</li>
                        </Link>
                    </ul>
                    <ul className={isActive("/vote") ? "on" : ""}>
                        <Link to="/vote/all">
                            <li></li>
                            <li>투표</li>
                        </Link>
                    </ul>
                    <ul className={isActive("/mypage") ? "on" : ""}>
                        <Link to="/mypage">
                            <li></li>
                            <li>마이</li>
                        </Link>
                    </ul>
                </div>
            </div>
            <section
                id="moveBtn"
                className="moveTop"
                style={{
                    display: showMoveTop ? 'block' : 'none',
                    opacity: showMoveTop ? 1 : 0,
                    bottom: `${moveTopBottom}px`, // bottom 위치 변경
                }}
                onClick={handleMoveTopClick} // 클릭 이벤트 추가
            >
                <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_top.svg" alt="Move to Top"/>
            </section>
            <section className="footer">
                <ul className="btnBox">
                    {auth.isAuthenticated ? (
                        <>
                            <li onClick={handleLogout}>로그아웃</li>
                        </>
                    ) : (
                        <Link to="/login"><li>로그인</li></Link>
                    )}
                    <li className="pc">PC버전</li>
                    <Link to="https://www.dcinside.com/" target="_blank" rel="noreferrer"><li>디시인사이드</li></Link>
                </ul>
                <ul className="items">
                    <Link to="https://www.dcinside.com/company" target="_blank" rel="noreferrer"><li className="mx">회사소개</li></Link>
                    <Link to="https://www.dcinside.com/company#recruit" target="_blank" rel="noreferrer"><li className="mx">인재채용</li></Link>
                    <Link to="https://www.dcinside.com/company#alliance" target="_blank" rel="noreferrer"><li className="mx">제휴안내</li></Link>
                    <Link to="https://nstatic.dcinside.com/dc/dcad/w/index.html" target="_blank" rel="noreferrer"><li className="mx">광고안내</li></Link>
                    <Link to="/policy"><li>이용약관</li></Link>
                    <Link to="/policy/privacy"><li><span>개인정보처리방침</span></li></Link>
                    <Link to="/feedback"><li>피드백센터</li></Link>
                    <li>등록번호 : 자00525</li>
                    <li>기사배열 책임자 : 박유진</li>
                    <li>청소년보호 책임자 : 박주돈</li>
                </ul>
                <ul className="copyright">
                    Copyright ⓒ 1999 - 2024 dcinside. All rights reserved.
                </ul>
                <ul className="info">
                    디시트렌드에서 사용되는 모든 인물 이미지와 콘텐츠는 저작권 및 초상권을 침해하지 않도록 최선을 다하고 있습니다.<br/>
                    만약 저작권 또는 초상권 침해와 관련된 문제가 발생할 경우, 신속하고 적절한 조치를 취할 것을 약속드립니다.<br/>
                    문제가 발생하면 <Link to="/feedback"><span>피드백 센터</span></Link>를 통해 신고해 주시기 바랍니다.
                </ul>
            </section>
        </>
    );
}

export default Footer;
