import StickyDefault from "../layout/stickyDefault";
import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";

function Gather() {
    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="rankingMain">
                    <div className="mainTitle">
                        <ul className="tit">투표 모아보기</ul>
                    </div>
                </div>
                <div className="gatherVote">
                    <div className="tab">
                        <ul className="on">전체</ul>
                        <ul>연예인</ul>
                        <ul>유튜버</ul>
                        <ul>스포츠인</ul>
                    </div>
                    <div className="voteTitle">실시간 인기투표</div>
                    <div className="voteBox">
                        <Swiper
                            direction='vertical'
                            slidesPerView='auto'
                            spaceBetween={0}
                            touchReleaseOnEdges={true}
                            breakpoints={{
                                480: {
                                    direction: 'horizontal',
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    direction: 'horizontal',
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">아이돌 걸그룹 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202404/BF.36389450.1.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">아이돌 보이그룹 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://file2.nocutnews.co.kr/newsroom/image/2022/01/31/202201312047237692_0.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">트로트 가수 남성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="divideLine"></div>
                    <div className="voteTitle">투표율 급상승</div>
                    <div className="voteBox">
                        <Swiper
                            direction='vertical'
                            slidesPerView='auto'
                            spaceBetween={0}
                            touchReleaseOnEdges={true}
                            breakpoints={{
                                480: {
                                    direction: 'horizontal',
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    direction: 'horizontal',
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://img.tvreportcdn.de/cms-content/uploads/2024/05/10/55571fb3-142e-4439-be30-ed3a33f4ed3e.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">예능인 남성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.thetitlenews.net/news/photo/202309/2337_4003_554.png" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">예능인 여성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202407/ZA.36891140.1.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">배우 남성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className="gatherList">
                        <div className="voteTitle">투표 모아보기</div>
                        <div className="sortBox">
                            <div className="sort">최신순<div className="arw s16"></div></div>
                            <div className="sortSelect">
                                <ul className="on">최신순<div></div></ul>
                                <ul>조회수순<div></div></ul>
                            </div>
                        </div>
                        <div className="list">
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://i.namu.wiki/i/ZnBMAAGJaiFKqDmASXCt-977Xuq6gLA-G8AsD4K1BKCVBEzrjISoW9QyfcSKPnacwuBpCGSSyBtCJv8E-UocNQ.webp" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">아이돌 남성 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://flexible.img.hani.co.kr/flexible/normal/800/450/imgdb/original/2023/0109/20230109503590.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">한국 여성 골퍼 순위</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://spnimage.edaily.co.kr/images/photo/files/NP/S/2017/07/PS17072200023.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">보디빌딩 유튜버 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://cdn.mediatoday.co.kr/news/photo/202402/316275_443634_08.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">아이돌 여성 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://png.pngtree.com/thumb_back/fw800/background/20230411/pngtree-football-player-shooting-pose-flame-effect-advertising-background-image_2283569.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">세계 축구선수 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://res.heraldm.com/content/image/2021/09/26/20210926000264_0.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">트로트 가수 여성 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://cdn.ardentnews.co.kr/news/photo/202310/1550_5814_2358.png" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">먹방 유튜버 순위</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://pickcon.co.kr/site/data/img_dir/2022/12/01/2022120180065_0.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">배우 여성 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://i.namu.wiki/i/e-QmywxRwR_zVXLEB7vta63d8JV7clet6DTPyAQW4kWSTzwM-HjatNJyuPE9EDBLM2uwJH9A8X-ncNR3tB7pfQ.webp" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">3세대 레전드 아이돌 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://cdnimg.melon.co.kr/resource/image/cds/musicstory/imgUrl20171103060747001.jpg/melon/optimize/90" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">2세대 레전드 아이돌 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://img.sbs.co.kr/newsnet/etv/upload/2018/12/17/30000619470_700.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">락(ROCK) 가수 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                            <ul className="hoverImgPt">
                                <li className="thumb"><img src="https://pds.joongang.co.kr//news/component/htmlphoto_mmdata/201709/28/8c8a32cf-2b07-46a6-9ad2-1eb50487e997.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">7080 발라드의 전설 남성 랭킹</dl>
                                    <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="listMoreWrap">
                            <div className="btnListMore">더보기<img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_arw_down_white.svg" alt=""/></div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Gather;