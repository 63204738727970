import React, { useRef } from 'react';
import StickyDefault from "../layout/stickyDefault";
import {Link} from "react-router-dom";

function Privacy() {
    // 각 항목에 대한 참조 생성
    const sectionRefs = {
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null),
        5: useRef(null),
        6: useRef(null),
        7: useRef(null),
        8: useRef(null),
        9: useRef(null),
        10: useRef(null),
        11: useRef(null)
    };

    // 클릭 핸들러
    const scrollToSection = (sectionKey) => {
        if (sectionRefs[sectionKey] && sectionRefs[sectionKey].current) {
            // 요소의 위치와 높이를 계산
            const element = sectionRefs[sectionKey].current;
            const rect = element.getBoundingClientRect();
            const offsetTop = rect.top + window.scrollY;
            const scrollTop = offsetTop - 90; // 70px 위로 이동

            // 스크롤 위치를 조정
            window.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="policy">
                    <div className="title">디시트렌드 개인정보 처리방침</div>
                    <div className="index">
                        <ul>목차</ul>
                        <ul>
                            <li onClick={() => scrollToSection('1')}>제1조 (개인정보 처리목적)</li>
                            <li onClick={() => scrollToSection('2')}>제2조 (처리하는 개인정보의 항목)</li>
                            <li onClick={() => scrollToSection('3')}>제3조 (개인정보의 처리 및 보유 기간)</li>
                            <li onClick={() => scrollToSection('4')}>제4조 (개인정보 파기 절차 및 방법)</li>
                            <li onClick={() => scrollToSection('5')}>제5조 (개인정보 제3자 제공)</li>
                            <li onClick={() => scrollToSection('6')}>제6조 (개인정보 처리업무의 위탁)</li>
                            <li onClick={() => scrollToSection('7')}>제7조 (개인정보 안전성 확보조치)</li>
                            <li onClick={() => scrollToSection('8')}>제8조 (이용자 및 법정대리인의 권리・의무 및 행사방법)</li>
                            <li onClick={() => scrollToSection('9')}>제9조 (개인정보 책임자 및 담당부서)</li>
                            <li onClick={() => scrollToSection('0')}>제10조 (정보주체의 권익침해에 대한 구제방법)</li>
                            <li onClick={() => scrollToSection('11')}>제11조 (개인정보처리방침의 시행 및 변경에 관한 사항)</li>
                        </ul>
                    </div>
                    <div className="box">
                        <ul>
                            <li ref={sectionRefs['1']}>제1조 (개인정보 처리목적)</li>
                            <li>
                                <dl>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리된 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</dl>
                                <div className="stit">1. 이용자 관리 및 서비스 제공</div>
                                <dl>
                                    <dt>
                                        <div>소셜로그인을 통한 본인 확인, 이용자 자격 유지 및 관리, 투표권 구매 및 관리, 뉴스 댓글 작성 및 관리 등 디시트렌드 서비스 제공과 관련된 목적으로 개인정보를 처리합니다.</div>
                                    </dt>
                                </dl>
                                <div className="stit">2. 투표권 구매 및 결제 처리</div>
                                <dl>
                                    <dt>
                                        <div>소투표권 구매 시 필요한 결제 처리, 결제 정보 관리 및 거래 내역 확인 등을 목적으로 개인정보를 처리합니다.</div>
                                    </dt>
                                </dl>
                                <div className="stit">3. 서비스 개선 및 신규 서비스 개발</div>
                                <dl>
                                    <dt>
                                        <div>소서비스 이용 기록 분석을 통한 기존 서비스 개선, 새로운 기능 개발 및 향상된 사용자 경험 제공을 목적으로 개인정보를 처리합니다.</div>
                                    </dt>
                                </dl>
                                <div className="stit">4. 안전한 서비스 환경 구축 및 관리</div>
                                <dl>
                                    <dt>
                                        <div>부정 이용 방지, 관계 법령 및 디시트렌드 이용약관 위반 이용자에 대한 제한 조치, 보안 및 개인정보 보호를 포함한 안전한 서비스 환경 구축 및 관리 목적으로 개인정보를 처리합니다.</div>
                                    </dt>
                                </dl>
                                <div className="stit">5. 저작권 침해 신고 처리</div>
                                <dl>
                                    <dt>
                                        <div>피드백 센터를 통한 저작권 침해 신고 접수 및 처리, 신고자 및 권리자 정보 관리 목적으로 개인정보를 처리합니다.</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['2']}>제2조 (처리하는 개인정보의 항목)</li>
                            <li>
                                <dl>회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집합니다.</dl>
                                <div className="stit">1. 정보주체의 동의를 받지 않고 처리하는 개인정보 항목</div>
                                <dl>
                                    <dt>
                                        <div>회사는 법령에 따라 필요한 경우에 한해 정보주체의 동의 없이 다음의 개인정보 항목을 처리할 수 있습니다.</div>
                                        <div className="subtit">[서비스 이용 관리]</div>
                                        <div><strong>⦁ 법적 근거:</strong> 「개인정보 보호법」 제15조 제1항 제2호 (‘법령상 의무 준수’)</div>
                                        <div><strong>⦁ 수집・이용 항목:</strong> 서비스 이용 기록, 접속 로그, 접속 IP 정보</div>
                                    </dt>
                                </dl>
                                <div className="stit">2. 정보주체의 동의를 받아 처리하는 개인정보 항목</div>
                                <dl>
                                    <dt>
                                        <div>디시트렌드는 다음의 개인정보 항목을 「개인정보 보호법」 제15조 제1항 제1호 및 제22조 제1항 제7호에 따라 정보주체의 동의를 받아 처리하고 있습니다.</div>
                                        <div>소투표권 구매 시 필요한 결제 처리, 결제 정보 관리 및 거래 내역 확인 등을 목적으로 개인정보를 처리합니다.</div>
                                        <div className="subtit">[소셜로그인 및 관리]</div>
                                        <div><strong>⦁ 수집・이용 항목:</strong> 이름(필수), 이메일 주소, 소셜로그인 시 닉네임, 소셜로그인 계정 식별값</div>
                                        <div><strong>⦁ 목적:</strong> 본인 확인, 이용자 자격 유지 및 관리, 각종 고지 및 통지</div>
                                        <div className="subtit">[투표권 구매 및 관리]</div>
                                        <div><strong>⦁ 수집・이용 항목:</strong> 투표권 구매 내역, 결제 정보</div>
                                        <div><strong>⦁ 목적:</strong> 투표권 구매 처리, 투표권 관리, 결제 확인</div>
                                        <div className="subtit">[뉴스 댓글 작성 및 관리]</div>
                                        <div><strong>⦁ 수집・이용 항목:</strong> 뉴스 댓글 작성 시 아이디 (소셜로그인 이용 시), 댓글 내용</div>
                                        <div><strong>⦁ 목적:</strong> 뉴스 댓글 작성 및 관리, 사용자 간 소통 지원</div>
                                        <div className="subtit">[생년월일 및 성별 정보] (선택적 수집 항목)</div>
                                        <div><strong>⦁ 수집・이용 항목:</strong> 생년월일, 성별</div>
                                        <div><strong>⦁ 목적:</strong> 트렌드 리포트의 투표 통계 분석에 활용</div>
                                        <div className="subtit">[저작권 침해 관련 신고]</div>
                                        <div><strong>⦁ 수집・이용 항목:</strong></div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 신고자 정보: 이름, 이메일, 전화번호</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 권리자 정보:</div>
                                        <div style={{paddingLeft:'32px'}}>⦁ 개인: 이름, 이메일, 전화번호, 신분증</div>
                                        <div style={{paddingLeft:'32px'}}>⦁ 단체: 단체명, 이메일, 전화번호, 증빙서류</div>
                                        <div><strong>⦁ 목적:</strong> 저작권 침해 신고 접수 및 처리, 관련 분쟁 해결</div>
                                    </dt>
                                </dl>
                                <div className="stit">3. 서비스 이용과정에서 자동으로 생성 및 수집되는 정보</div>
                                <dl>
                                    <dt>
                                        <div>서비스 이용과정에서 IP 주소, 서비스 이용기록, 방문기록, 디바이스 정보(운영체제, 브라우저 유형, 인터페이스, 언어 설정) 등이 자동으로 수집될 수 있습니다.</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['3']}>제3조 (개인정보의 처리 및 보유 기간)</li>
                            <li>
                                <dl>회사는 소셜로그인 기반 서비스로 이용자의 개인정보를 원칙적으로 즉시 파기합니다. 법령에 따라 보관이 필요한 경우에는 예외적으로 해당 기간 동안 개인정보를 보유할 수 있습니다.</dl>
                                <div className="stit">1. 회원가입 및 관리</div>
                                <dl>
                                    <dt>
                                        <div>⦁ 이용자가 소셜로그인 탈퇴 시 모든 개인정보는 즉시 파기됩니다.</div>
                                        <div>⦁ 단, 관련 법령에 따라 수사・조사 등이 진행 중인 경우에는 해당 수사・조사 종료 시까지 개인정보를 보유할 수 있습니다.</div>
                                    </dt>
                                </dl>
                                <div className="stit">2. 재화 또는 서비스 제공</div>
                                <dl>
                                    <dt>
                                        <div>⦁ 투표권 구매 등 유료 서비스 제공과 관련된 개인정보는 서비스 제공 완료 후 즉시 파기됩니다. </div>
                                        <div>⦁ 단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령에 따라 보존이 필요한 경우, 해당 법령에서 정한 기간 동안 보유할 수 있습니다</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 표시・광고에 관한 기록: 6개월</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 전자금융 거래에 관한 기록: 5년</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년</div>
                                    </dt>
                                </dl>
                                <div className="stit">3. 기타 법령에 따른 보존</div>
                                <dl>
                                    <dt>
                                        <div>⦁ 관계 법령 위반에 따른 수사・조사 등의 사유로 인하여 해당 수사・조사가 종료될 때까지 개인정보를 보유할 수 있습니다.</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['4']}>제4조 (개인정보 파기 절차 및 방법)</li>
                            <li>
                                <dl>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 더 이상 필요하지 않게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. 다만, 회사 내부 방침 또는 관계 법령에서 정한 보관기간이 있을 경우, 해당 기간 동안 보관 후 파기됩니다.</dl>
                                <dl> ※ 다른 법령에 따라 보존되는 개인정보 항목과 그 보존 근거는 “개인정보의 처리 및 보유기간”에서 확인할 수 있습니다.</dl>
                                <div className="stit">1. 파기 절차</div>
                                <dl>
                                    <dt>
                                        <div>⦁ 개인정보는 보유기간이 경과되거나 처리목적이 달성된 후, 별도의 데이터베이스(DB)로 옮겨져 내부 방침 및 기타 관련 법령에 따라 일정 기간 저장된 후 삭제됩니다.</div>
                                        <div>⦁ 해당 개인정보는 법령에 따라 보존되는 경우가 아니면, 보유 목적이 달성된 이후 즉시 파기됩니다.</div>
                                        <div>⦁ 개인정보 파기는 자동화된 시스템을 통해 처리되며, 파기 과정에서 발생하는 모든 처리 내역은 전산 시스템을 통해 기록되어 관리됩니다.</div>
                                    </dt>
                                </dl>
                                <div className="stit">2. 파기 방법</div>
                                <dl>
                                    <dt>
                                        <div>⦁ 전자적 파일 형태의 정보: 전자적 파일 형태로 저장된 개인정보는 복구할 수 없는 기술적 방법을 사용하여 삭제됩니다. 삭제된 데이터는 어떠한 방법으로도 복구할 수 없습니다.</div>
                                        <div>⦁ 종이 문서 형태의 정보: 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. 이 과정은 철저히 기록되고 관리됩니다.</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['5']}>제5조 (개인정보 제3자 제공)</li>
                            <li>
                                <dl>회사는 정보주체의 개인정보를 “개인정보의 처리 목적”에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하며, 그 외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</dl>
                                <dl>
                                    <dt>
                                        <div className="stit">⦁ 정보주체의 동의를 받아 개인정보를 제3자 제공하는 경우</div>
                                        <div>회사는 원활한 서비스 제공을 위해 다음의 경우 개인정보 보호법 제17조 제1항 제1호에 따라 정보주체의 동의를 얻어 필요 최소한의 범위로만 제공합니다.</div>
                                        <table>
                                            <tr className="header">
                                                <td style={{width:'15%'}}>제공 받는자</td>
                                                <td style={{width:'20%'}}>제공 목적</td>
                                                <td style={{width:'20%'}}>제공 항목</td>
                                                <td>보유 및 이용 기간</td>
                                            </tr>
                                            <tr>
                                                <td>팅코</td>
                                                <td>
                                                    • 뉴스 댓글 관리<br/>
                                                    • 투표권 구매 및 환불 관리<br/>
                                                    • 피드백센터 운영<br/>
                                                </td>
                                                <td>
                                                    • 이름, 이메일 주소, 뉴스 댓글 작성 시 닉네임<br/>
                                                    • 투표권 구매 내역, 결제 정보(결제 수단, 결제 금액 등)<br/>
                                                    • 피드백 센터 이용 시 수집된 신고자 정보 및 권리자 정보<br/>

                                                </td>
                                                <td>
                                                    • 해당 서비스 제공 목적 달성 시까지 또는 법령에 따른 보유 기간까지<br/>
                                                    ※ 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료 시까지 보관
                                                </td>
                                            </tr>
                                        </table>
                                        <div>회사는 개인정보보호법 등 관계 법령이 정하는 경우, 그 밖에 정부 관계부처가 합동으로 발표한 「긴급상황 시 개인정보 처리 및 보호수칙」에 따라 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우, 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 「긴급상황 시 개인정보 처리 및 보호수칙에 대한 내용」 은 <Link to="https://www.pipc.go.kr/np/cop/bbs/selectBoardArticle.do?bbsId=BS217&nttId=7641" target="_blank" rel="noreferrer">여기</Link>를 눌러 확인하실 수 있습니다.</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['6']}>제6조 (개인정보 처리업무의 위탁)</li>
                            <li>
                                <dl>회사는 서비스의 원활한 제공을 위해 필요한 범위 내에서 개인정보의 처리를 일부 위탁하고 있습니다. 자세한 위탁사항은 아래의 표에서 확인하실 수 있습니다.</dl>
                                <dl>회사는 개인정보 처리 업무를 위탁할 때, 위탁 받은 업체가 개인정보 보호 관련 법령을 준수하도록 철저히 관리·감독하고 있습니다. 이를 위해 수탁 업체와의 계약 시 개인정보 보호 조항을 포함하고, 정기적인 감사와 평가를 통해 수탁 업체의 개인정보 처리 현황을 지속적으로 점검합니다. 또한, 수탁 업체가 개인정보 보호에 필요한 기술적, 관리적, 물리적 조치를 이행할 수 있도록 지원하고 있습니다. 이를 통해 회사는 이용자의 개인정보가 안전하게 처리될 수 있도록 최선을 다하고 있습니다.</dl>
                                <dl>
                                    <table>
                                        <tr className="header">
                                            <td style={{width:'20%'}}>제공 받는자</td>
                                            <td>제공 목적</td>
                                        </tr>
                                        <tr>
                                            <td>헥토 PG</td>
                                            <td>결제 처리 및 결제 관련 업무 수행</td>
                                        </tr>
                                        <tr>
                                            <td>팅코</td>
                                            <td>뉴스 댓글 관리, 투표권 구매 및 환불 관리, 피드백센터 운영</td>
                                        </tr>
                                    </table>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['7']}>제7조 (개인정보 안전성 확보조치)</li>
                            <li>
                                <dl>회사는 개인정보 보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적 보호조치를 시행하고 있습니다.</dl>
                                <div className="stit">1. 개인정보 취급자의 최소화 및 교육</div>
                                <dl>
                                    <dt>회사는 개인정보를 처리하는 직원을 최소화하며, 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보 보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시합니다.</dt>
                                </dl>
                                <div className="stit">2. 개인정보에 대한 접근 제한</div>
                                <dl>
                                    <dt>회사는 개인정보를 처리하는 시스템에 대한 접근 권한의 부여, 변경, 말소 절차를 수립하고 운영합니다. 침입탐지시스템을 이용하여 외부로부터의 무단 접근을 통제하며, 접근 기록을 최소 2년 이상 보관하고 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.</dt>
                                </dl>
                                <div className="stit">3. 개인정보의 암호화</div>
                                <dl>
                                    <dt>법령에서 암호화를 요구하는 중요정보는 암호화되어 저장 및 관리됩니다. 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안 기능을 적용하고 있습니다.</dt>
                                </dl>
                                <div className="stit">4. 해킹 등에 대비한 기술적 대책</div>
                                <dl>
                                    <dt>회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신 및 점검을 실시합니다. 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 기술적 및 물리적으로 감시 및 차단합니다.</dt>
                                </dl>
                                <div className="stit">5. 접속 기록의 보관 및 위변조 방지</div>
                                <dl>
                                    <dt>개인정보 처리 시스템에 접속한 기록(웹 로그, 요약 정보 등)을 최소 2년 이상 보관하고 관리하며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.</dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['8']}>제8조 (이용자 및 법정대리인의 권리・의무 및 행사방법)</li>
                            <li>
                                <dl>회사는 이용자의 권리를 존중하며, 이용자는 개인정보의 열람・정정・삭제・처리정지 및 동의 철회 요구 등의 권리(이하 "권리행사"라 함)를 행사할 수 있습니다. 권리행사의 세부적인 사항은 아래를 참고해 주시기 바랍니다.</dl>
                                <div className="stit">⦁ 권리행사 절차</div>
                                <dl>
                                    <dt>이용자는 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편 또는 고객센터를 통해 권리행사를 요청할 수 있으며, 회사는 이에 대해 지체 없이 조치를 취할 것입니다.</dt>
                                </dl>
                                <div className="stit">⦁ 개인정보 열람 및 변경</div>
                                <dl>
                                    <dt>
                                        이용자는 서비스 내의 설정 기능을 통해 직접 개인정보를 조회 및 변경할 수 있습니다.
                                        <div style={{paddingLeft:'16px'}}>⦁ 개인정보 조회 및 변경: 디시트렌드 > 설정 > 개인정보 관리</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 동의 철회 및 소셜로그인 탈퇴: 디시트렌드 > 설정 > 탈퇴하기</div>
                                    </dt>
                                </dl>
                                <div className="stit">⦁ 법정대리인의 권리행사</div>
                                <dl>
                                    <dt>정보주체가 만 14세 이상임을 확인하고 소셜로그인 가입을 허용하며, 법정대리인도 정보주체를 대신하여 권리행사를 할 수 있습니다. 이 경우, 『개인정보 처리 방법에 관한 고시』 별지 제11호 서식에 따른 위임장을 제출해야 합니다.</dt>
                                </dl>
                                <div className="stit">⦁ 권리의 제한</div>
                                <dl>
                                    <dt>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항 및 제37조 제2항에 따라 제한될 수 있으며, 개인정보의 정정 및 삭제 요구 시 다른 법령에 따라 수집된 개인정보가 삭제될 수 없습니다.</dt>
                                </dl>
                                <div className="stit">⦁ 자동화된 결정에 대한 권리</div>
                                <dl>
                                    <dt>회사는 자동화된 결정을 하지 않으며, 자동화된 결정이 발생하는 경우 그 기준과 절차, 개인정보 처리 방식, 거부 또는 설명 요구 절차를 명확히 공개하겠습니다.</dt>
                                </dl>
                                <div className="stit">⦁ 고객상담 접수 및 문의</div>
                                <dl>
                                    <dt>
                                        이용자는 아래 연락처를 통해 개인정보와 관련된 문의를 할 수 있으며, 회사는 이용자의 권리 행사가 신속하게 처리되도록 최선을 다하겠습니다.
                                        <div style={{paddingLeft:'16px'}}>⦁ 이메일: dctrend@rankify.best</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['9']}>제9조 (개인정보 책임자 및 담당부서)</li>
                            <li>
                                <dl>사용자가 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로 연락해 주시기 바랍니다.</dl>
                                <dl>당근은 사용자 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.</dl>
                                <dl>
                                    <dt>
                                        <div style={{paddingLeft:'16px'}}>⦁	개인정보 보호책임자: 권재웅</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 이메일: dctrend@rankify.best</div>
                                        <div style={{paddingLeft:'16px'}}>⦁ 담당부서: 전략개발본부</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['10']}>제10조 (정보주체의 권익침해에 대한 구제방법)</li>
                            <li>
                                <dl>이용자는 개인정보 침해에 대한 피해 구제, 상담 등을 위해 아래 기관에 문의할 수 있습니다.</dl>
                                <dl>
                                    <dt>
                                        <div className="subtit">⦁ 개인정보 침해신고센터(한국인터넷진흥원 운영)</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	소관업무: 개인정보 침해사실 신고, 상담 신청</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	홈페이지: privacy.kisa.or.kr</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	전화: (국번없이) 118</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	주소: 전라남도 나주시 진흥길 9 한국인터넷진흥원</div>
                                        <div className="subtit">⦁ 개인정보 분쟁조정위원회</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	홈페이지: www.kopico.go.kr</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	전화: 1833-6972</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	주소: 서울특별시 종로구 세종대로 209 정부서울청사 12층</div>
                                        <div className="subtit">⦁ 경찰청 사이버수사국</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	전화: (국번없이) 182</div>
                                        <div style={{paddingLeft:'16px'}}>⦁	홈페이지: 사이버범죄 신고시스템 (ECRM)</div>
                                    </dt>
                                </dl>
                                <dl>회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 개인정보 관련 고객상담 접수 부서로 연락해 주시기 바랍니다.</dl>
                                <dl>『개인정보 보호법』 제25조(개인정보의 열람), 제26조(개인정보의 정정 삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li ref={sectionRefs['11']}>제11조 (개인정보처리방침의 시행 및 변경에 관한 사항)</li>
                            <li>
                                <dl>이 개인정보 처리방침은 2024년 10월 01일부터 시행되며 회사는 정부의 정책 또는 관계 법령, 서비스의 변경사항을 반영하기 위한 목적으로 개인정보처리방침을 수정할 수 있으며, 이때 최소 7일 전부터 공지사항을 통해 변경 사항을 고지하겠습니다.</dl>
                            </li>
                        </ul>

                    </div>
                </div>

            </section>
        </>
    );
}

export default Privacy;