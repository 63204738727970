// loginHandler.js
import { useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authAtom';
import { useNavigate } from 'react-router-dom';

const useLoginHandler = () => {
    const setAuthState = useSetRecoilState(authState);
    const navigate = useNavigate();

    const handleLogin = (user, from) => {

        const token = "afsasdfasdfasdfasdf";
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('user', JSON.stringify(user));

        setAuthState({
            isAuthenticated: true,
            token: token,
            user: user,
        });

        navigate(from, { replace: true });
    };

    return { handleLogin };
};

export default useLoginHandler;
