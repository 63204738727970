import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Radio, Space } from "antd";

function CommentNotify({ isOpen, onClose, isReset }) {
    const [value, setValue] = useState(1);
    const [text, setText] = useState("");

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const handleTextChange = (e) => {
        // 500자 초과 시 잘라내기
        if (e.target.value.length <= 500) {
            setText(e.target.value);
        }
    };

    const handleModifyConfirm = () => {
        isReset();
        handleClose();
    };

    const handleClose = () => {
        setText('');  // textarea 내용 초기화
        onClose();    // 모달 닫기
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <section className="layerDefault notify">
            <div className="inbox">
                <div className="top">댓글 신고</div>
                <div className="close" onClick={handleClose}>
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/>
                </div>
                <div className="cont">
                    <div className="commentNotify">
                        <ul className="tit">신고 사유를 선택해주세요.</ul>
                        <ul className="list">
                            <Radio.Group onChange={onChange} value={value}>
                                <Space direction="vertical">
                                    <Radio value={1}>스팸홍보/도배글</Radio>
                                    <Radio value={2}>청소년 유해 내용</Radio>
                                    <Radio value={3}>욕설/생명경시/혐오/차별적 표현</Radio>
                                    <Radio value={4}>개인정보 노출</Radio>
                                    <Radio value={5}>기타</Radio>
                                </Space>
                            </Radio.Group>
                            <textarea
                                placeholder="신고 사유를 작성해주세요"
                                disabled={value !== 5}
                                value={text}
                                onChange={handleTextChange}
                            ></textarea>
                            <div className="limit">{text.length}/500</div>
                        </ul>
                    </div>
                </div>
                <div className="bot">
                    <ul className="cancel" onClick={handleClose}>취소</ul>
                    <ul className="confirm" onClick={handleModifyConfirm}>신고하기</ul>
                </div>
            </div>
        </section>,
        document.body
    );
}

export default CommentNotify;
