import React, { useState, useEffect, useRef } from 'react';

const CustomSelect = ({ title, options, selectedValue, setSelectedValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);

    // 외부 클릭 시 드롭다운 닫기
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 선택된 값이 변경될 때 부모 컴포넌트에 알리기
    useEffect(() => {
        // 부모 컴포넌트에 선택된 값을 직접 전달하는 부분
        if (setSelectedValue) {
            setSelectedValue(selectedValue);
        }
    }, [selectedValue, setSelectedValue]);

    const handleItemBoxClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedValue(value); // 상태 업데이트
        setIsOpen(false);
    };

    return (
        <div className="sortItemBox" ref={selectRef}>
            <div
                className={`itemBox ${isOpen ? 'on' : ''}`}
                onClick={handleItemBoxClick}
            >
                {title.trim() && <ul className="tit">{title}</ul>}
                <ul className="sel">
                    {options.find(option => option.value === selectedValue)?.label || 'Select'}
                </ul>
            </div>
            {isOpen && (
                <div className="selectList">
                    {options.map(option => (
                        <ul
                            key={option.value}
                            data-value={option.value}
                            className={selectedValue === option.value ? 'on' : ''}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </ul>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
