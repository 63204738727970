import React from "react";
import NewsSubMenu from "./submenu";
import StickyDefault from "../layout/stickyDefault";
import RankingNewsBox from "../../component/rankingNewsBox";

const rankingData = [
    {
        title: "아이돌 걸그룹 랭킹",
        rankItems: [
            { rank: 1, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "뉴진스", votes: "4,224표" },
            { rank: 2, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "에스파", votes: "4,224표" },
            { rank: 3, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "아이브", votes: "4,224표" },
            { rank: 4, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "QWER", votes: "4,224표" },
            { rank: 5, imageUrl: "https://image.xportsnews.com/contents/images/upload/article/2024/0519/mb_1716106571678771.jpeg", title: "아일릿", votes: "4,224표" }
        ],
        newsItems: [
            { rank: 1, title: "혜인 합류…뉴진스 완전체 국내 음방 무대 선보인다", imageUrl: "https://img.etoday.co.kr/pto_db/2024/06/600/20240608095743_2034978_1200_901.jpg" },
            { rank: 2, title: "에스파 '슈퍼노바', 스포티파이 1억 스트리밍 달성…통산 10번째", imageUrl: "https://thumb.mt.co.kr/06/2024/07/2024071016194159841_1.jpg/dims/optimize/" },
            { rank: 3, title: "아이브 안유진 화보, 독보적 아우라 발산", imageUrl: "https://contents-cdn.viewus.co.kr/image/2023/12/CP-2022-0017/image-de4d5a79-bbe3-4c2e-84a7-f36976345663.jpeg" },
            { rank: 4, title: "QWER, 실력파 춤 노래 연주 모두 '만점'", imageUrl: "https://www.cbci.co.kr/news/photo/202407/470830_279178_189.jpg" },
            { rank: 5, title: "아일릿 원희, 목발 짚고 안쓰러운 日 출국…한쪽 팔로 짐까지 바리바리", imageUrl: "https://image.xportsnews.com/contents/images/upload/article/2024/0519/mb_1716106571678771.jpeg" }
        ]
    },
    {
        title: "트로트 가수 랭킹",
        rankItems: [
            { rank: 1, imageUrl: "https://newsimg.hankookilbo.com/cms/articlerelease/2021/10/07/c3f00183-307b-4530-a48b-f628e54bdf61.jpg", title: "임영웅", votes: "8,187표" },
            { rank: 2, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "전유진", votes: "4,224표" },
            { rank: 3, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "이미나", votes: "4,224표" },
            { rank: 4, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "이찬원", votes: "4,224표" },
            { rank: 5, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "박서진", votes: "4,224표" }
        ],
        newsItems: [
            { rank: 1, title: "임영웅은 팬클럽 이름으로,‘영웅시대’는 스타 빛내려 기부 “善순환”", imageUrl: "https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202309/26/27ccde99-eb16-49f8-a91b-d263a5def658.jpg" },
            { rank: 2, title: "전유진, '현역가왕’ 1위 등극...‘트롯계 뉴진스’", imageUrl: "https://www.dgmagazine.co.kr/news/photo/202402/5119_5970_3623.jpg" },
            { rank: 3, title: "최상위권 싹쓸이! 트롯 오디션 10대 열풍 이유", imageUrl: "https://ilyo.co.kr/contents/article/images/2024/0131/1706666626470115.jpg" },
            { rank: 4, title: "이찬원 남동생, 얼마나 잘생겼길래… “아이돌 못지않은 인기” (‘한끗차이’)", imageUrl: "https://d2fc09gk1936lv.cloudfront.net/kbs/866x487/scf.static.kbs.co.kr/image/NBCONTENTSMYLOVEKBS/NBCONTENTSMYLOVEKBS_70000000398553_20220811_20220811145000_master_images_01.jpg" },
            { rank: 5, title: "박서진, '살림남' 10kg이상 감량한 서진 왕자의 다이어트 비결", imageUrl: "https://image.xportsnews.com/contents/images/upload/article/2024/0124/mb_1706052857115155.jpg" }
        ]
    },
    {
        title: "예능 방송인 랭킹",
        rankItems: [
            { rank: 1, imageUrl: "https://img.tvreportcdn.de/cms-content/uploads/2024/05/10/55571fb3-142e-4439-be30-ed3a33f4ed3e.jpg", title: "유재석", votes: "5,806표" },
            { rank: 2, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "강호동", votes: "4,000표" },
            { rank: 3, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "신동엽", votes: "3,500표" },
            { rank: 4, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "김구라", votes: "3,000표" },
            { rank: 5, imageUrl: "https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp", title: "이경규", votes: "2,800표" }
        ],
        newsItems: [
            { rank: 1, title: "혜인 합류…뉴진스 완전체 국내 음방 무대 선보인다", imageUrl: "https://img.etoday.co.kr/pto_db/2024/06/600/20240608095743_2034978_1200_901.jpg" },
            { rank: 2, title: "에스파 '슈퍼노바', 스포티파이 1억 스트리밍 달성…통산 10번째", imageUrl: "https://thumb.mt.co.kr/06/2024/07/2024071016194159841_1.jpg/dims/optimize/" },
            { rank: 3, title: "아이브 안유진 화보, 독보적 아우라 발산", imageUrl: "https://contents-cdn.viewus.co.kr/image/2023/12/CP-2022-0017/image-de4d5a79-bbe3-4c2e-84a7-f36976345663.jpeg" },
            { rank: 4, title: "QWER, 실력파 춤 노래 연주 모두 '만점'", imageUrl: "https://www.cbci.co.kr/news/photo/202407/470830_279178_189.jpg" },
            { rank: 5, title: "아일릿 원희, 목발 짚고 안쓰러운 日 출국…한쪽 팔로 짐까지 바리바리", imageUrl: "https://image.xportsnews.com/contents/images/upload/article/2024/0519/mb_1716106571678771.jpeg" }
        ]
    }
];

function RankingNews() {
    return (
        <>
            <StickyDefault/>
            <NewsSubMenu/>
            <section className="contentsWrap">
                <div className="rankChartWarp">
                    <div className="rankChart no700">
                        <RankingNewsBox rankingData={rankingData[0]} />
                        <RankingNewsBox rankingData={rankingData[1]} />
                        <RankingNewsBox rankingData={rankingData[2]} />
                    </div>
                    <div className="listLoader">
                        <div className="loader"></div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default RankingNews;