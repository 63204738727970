import React from 'react';
import ReactDOM from 'react-dom';


function CommentDelete({ isOpen, onClose, isReset }) {

    if (!isOpen) return null;

    const handleModifyConfirm = (e) => {
        isReset();
    };

    return ReactDOM.createPortal(
        <section className="layerDefault commentDelete">
            <div className="inbox">
                <div className="top">댓글 삭제</div>
                <div className="close" onClick={onClose}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                <div className="cont">
                    <div className="commentPassword">
                        <ul className="tit">작성자만 글을 삭제할 수 있습니다.</ul>
                        <ul className="txt">글 작성시 입력한 비밀번호를 입력하여<br/>글을 삭제할 수 있습니다.</ul>
                        <ul className="inp">
                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_password.svg" alt=""/>
                            <input name="password" type="password" placeholder="비밀번호"/>
                        </ul>
                    </div>
                </div>
                <div className="bot">
                    <ul className="cancel">취소</ul>
                    <ul className="confirm" onClick={handleModifyConfirm}>확인</ul>
                </div>
            </div>
        </section>,
        document.body
    );
}

export default CommentDelete;
