import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import StickyNewsDetail from "../layout/stickyNewsDetail";
import { Link, useParams } from "react-router-dom";
import { useApi } from '../../js/module/api';
import { formatDate } from '../../js/module/formatDate';
import KakaoShareButton from '../../component/KakaoShareButton';
import htmlToText from '../../js/module/HtmlToText';
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import useCopyUrl from '../../js/module/useCopyUrl';
import CommentWrap from "./comment/comment";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import Lottie from "lottie-react";
import NoNews from "../../assets/json/lottie_404_news.json";

function NewsDetail() {
    const apiUrl = process.env.REACT_APP_API_URL; // 환경 변수에서 API URL 가져오기
    const currentUrl = window.location.href;
    const [newsContents, setNewsContents] = useState([]);
    const [emotions, setEmotion] = useState([]);
    const [resent, setResent] = useState([]);
    const { categoryId, newsId } = useParams();
    const [isFontSizeBoxVisible, setIsFontSizeBoxVisible] = useState(false);
    const [isShareBoxVisible, setIsShareBoxVisible] = useState(false);
    const [selectedFontSize, setSelectedFontSize] = useState('ftSz18');
    const [pageError, setPageError] = useState(false); // 404 에러 상태 관리
    const copyUrlToClipboard = useCopyUrl();
    const { apiRequest } = useApi();
    const popularNews = 10;

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const url = apiUrl+`api/v1/news/${categoryId}/${newsId}?popularNews=${popularNews}`;
                const data = await apiRequest(url, {}, 'GET', '', '', false);
                setEmotion(data.data.emotions);
                setNewsContents(data.data.newsDeatil);
                setResent(data.data.resents);
                setPageError(false); // 에러가 없으면 404 페이지 표시 안 함
            } catch (error) {
                console.log(error.status)
                if (error.status === 404) {
                    setPageError(true); // 404 에러 발생 시 페이지 에러 상태 설정
                } else {
                    console.log("page: " + error);
                }
            }
        };
        if (categoryId && newsId) {
            fetchNews().then();
        }
    }, [categoryId, newsId, apiRequest, apiUrl]);

    const sendEmotion = async (type) => {
        try {
            const url = `${apiUrl}api/v1/emotions/save-and-return-summary`;
            const params = {
                "emotionType": type,
                "entityType": "NEWS",
                "entityId": newsId
            };
            const response = await apiRequest(url, params, 'POST');
            setEmotion(response.data);
        } catch (error) {
            console.error('Error sending emotion:', error);
        }
    };

    const toggleFontSizeBoxVisibility = () => {
        setIsFontSizeBoxVisible(prevState => !prevState);
    };
    const closeFontSizeBox = () => {
        setIsFontSizeBoxVisible(false);
    };
    const toggleShareBoxVisibility = () => {
        setIsShareBoxVisible(prevState => !prevState);
    };
    const closeShareBox = () => {
        setIsShareBoxVisible(false);
    };

    const handleFontSizeChange = (size) => {
        setSelectedFontSize(size);
        closeFontSizeBox();
    };

    return (
        <>
            {!pageError && (
                <>
                    <StickyNewsDetail title={decodeHtmlEntities(newsContents.title)} onShareClick={toggleShareBoxVisibility} onFontClick={toggleFontSizeBoxVisibility} />
                    <section className="contentsWrap">
                        <div className="newsLayout">
                            <div className="leftWrap">
                                <div className="articleWrap">
                                    <div className="category ftSz16">{newsContents.categoryName}</div>
                                    <div className="articleTitle ftSz36">{newsContents.title ? decodeHtmlEntities(newsContents.title) : <Skeleton width="100%" height={46} />}</div>
                                    <div className="info">
                                        {newsContents.authors && (
                                            <ul className="publisher">{newsContents.authors} 기자</ul>
                                        )}
                                        <ul className="date">
                                            {newsContents && newsContents.publishedAt && (
                                                <>입력 {formatDate(newsContents.publishedAt)}</>
                                            )}
                                        </ul>
                                        <ul className="btn">
                                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_share.svg" onClick={toggleShareBoxVisibility} alt=""/>
                                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_fontsize.svg" onClick={toggleFontSizeBoxVisibility} alt=""/>
                                        </ul>
                                    </div>
                                    <div className="layerBox">
                                        {isShareBoxVisible && (
                                            <div className="box share">
                                                <div className="tit">공유</div>
                                                <div className="close" onClick={closeShareBox}>
                                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" style={{ cursor: 'pointer' }} />
                                                </div>
                                                <div className="cont">
                                                    <ul onClick={copyUrlToClipboard}>
                                                        <li><div className="icoUrl"></div></li>
                                                        <li>URL복사</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <KakaoShareButton
                                                                title={decodeHtmlEntities(newsContents.title)}
                                                                description={htmlToText(newsContents.content,100)}
                                                                imageUrl={newsContents.thumbnail}
                                                                linkUrl={currentUrl}
                                                            />
                                                        </li>
                                                        <li>카카오톡</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        {isFontSizeBoxVisible && (
                                            <div className="box font">
                                                <div className="tit">글자 크기 설정</div>
                                                <div className="close" onClick={closeFontSizeBox}>
                                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" style={{ cursor: 'pointer' }} />
                                                </div>
                                                <div className="cont">
                                                    <ul
                                                        className={`ftSz16 ${selectedFontSize === 'ftSz16' ? 'on' : ''}`}
                                                        onClick={() => handleFontSizeChange('ftSz16')}
                                                    >
                                                        <li>가</li>
                                                        <li>작게</li>
                                                    </ul>
                                                    <ul
                                                        className={`ftSz18 ${selectedFontSize === 'ftSz18' ? 'on' : ''}`}
                                                        onClick={() => handleFontSizeChange('ftSz18')}
                                                    >
                                                        <li>가</li>
                                                        <li>보통</li>
                                                    </ul>
                                                    <ul
                                                        className={`ftSz20 ${selectedFontSize === 'ftSz20' ? 'on' : ''}`}
                                                        onClick={() => handleFontSizeChange('ftSz20')}
                                                    >
                                                        <li>가</li>
                                                        <li>크게</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="contents">
                                        <div className={`break-words content ${selectedFontSize}`}>
                                            <div dangerouslySetInnerHTML={{ __html: newsContents.content }} />
                                        </div>
                                    </div>
                                    {newsContents.keywords && newsContents.keywords.length > 0 && (
                                        <div className="tag">
                                            {newsContents.keywords.map((keyword, index) => (
                                                <ul key={index}>#{keyword}</ul>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="trendWidgetWrap">
                                    <iframe
                                        src="https://dev.dctrend.newshub.kr/widget/type_a.html"
                                        width="100%"
                                        height="262"
                                        title="trendWidgetB"
                                        style={{ border: 'none' }}
                                    ></iframe>
                                </div>
                                <div className="sectionTitleSub">
                                    <div className="title">이 기사, 어떠셨나요?</div>
                                </div>
                                <div className="newsVote">
                                    {emotions.map((item, index) => (
                                        <ul key={index} onClick={() => sendEmotion(item.emotionType)}>
                                            <li><img src={item.iconUrl} alt={item.name} /></li>
                                            <li>{item.name}</li>
                                            <li>{item.count}</li>
                                        </ul>
                                    ))}
                                </div>
                                <CommentWrap/>
                            </div>
                            <div className="rightWrap">
                                <div className="rightSticky">
                                    <div className="dcTrend">
                                        <div className="dcTitle">실시간 인기투표<div className="arw s24"></div></div>
                                        <Swiper
                                            direction='vertical'
                                            slidesPerView='auto'
                                            spaceBetween={0}
                                            touchReleaseOnEdges={true}
                                            breakpoints={{
                                                480: {
                                                    direction: 'horizontal',
                                                    slidesPerView: 'auto',
                                                    spaceBetween: 16,
                                                },
                                                1200: {
                                                    direction: 'vertical',
                                                    slidesPerView: 'auto',
                                                    spaceBetween: 0,
                                                }
                                            }}
                                        >
                                            <SwiperSlide>
                                                <ul className="hoverImgPt">
                                                    <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                                                    <li className="info">
                                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                                        <dl className="title">아이돌 걸그룹 랭킹</dl>
                                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                                    </li>
                                                </ul>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <ul className="hoverImgPt">
                                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202404/BF.36389450.1.jpg" alt=""/></li>
                                                    <li className="info">
                                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                                        <dl className="title">아이돌 보이그룹 랭킹</dl>
                                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                                    </li>
                                                </ul>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <ul className="hoverImgPt">
                                                    <li className="thumb"><img src="https://file2.nocutnews.co.kr/newsroom/image/2022/01/31/202201312047237692_0.jpg" alt=""/></li>
                                                    <li className="info">
                                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                                        <dl className="title">트로트 가수 남성 랭킹</dl>
                                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                                    </li>
                                                </ul>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className="stickyTitle"><span>{newsContents.categoryName}</span> 주요뉴스</div>
                                    <div className="popularNewsRight">
                                        {resent.map((item,index) => (
                                            <Link key={item.newsId} to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                                <ul>
                                                    <li>{index+1}</li>
                                                    <li>{decodeHtmlEntities(item.title)}</li>
                                                    <li>
                                                        <img
                                                            src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"}
                                                            alt={item.title || 'No Image'}
                                                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"; }}
                                                        />
                                                    </li>
                                                </ul>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            {pageError && (
                <section className="errNewsDetail">
                    <div className="img"><Lottie animationData={NoNews} loop={true} /></div>
                    <div className="title">페이지를 찾을 수 없습니다.</div>
                    <div className="txt">잘못된 주소이거나 해당 컨텐츠가<br />삭제되었을 수 있습니다.</div>
                    <Link to="/"><div className="btn">메인으로</div></Link>
                </section>
            )}
        </>
    );
}

export default NewsDetail;
