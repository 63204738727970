import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import StickyNewsDetail from "../layout/stickyNewsDetail";
import { Link, useParams } from "react-router-dom";
import useCopyUrl from "../../js/module/useCopyUrl";
import { useApi } from "../../js/module/api";
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import Skeleton from "react-loading-skeleton";
import { formatDate } from "../../js/module/formatDate";
import KakaoShareButton from "../../component/KakaoShareButton";
import htmlToText from "../../js/module/HtmlToText";
import CommentWrap from "./comment/comment";

function ContentsDetail() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const currentUrl = window.location.href;
    const [contents, setContents] = useState([]);
    const [emotions, setEmotion] = useState([]);
    const [resentList, setResentList] = useState([]);
    const [popularsList, setPopularsList] = useState([]);
    const { categoryId,contentsId } = useParams();
    const [isFontSizeBoxVisible, setIsFontSizeBoxVisible] = useState(false);
    const [isShareBoxVisible, setIsShareBoxVisible] = useState(false);
    const [selectedFontSize, setSelectedFontSize] = useState('ftSz18');
    const copyUrlToClipboard = useCopyUrl();
    const { apiRequest } = useApi();
    const recentNews = 4;
    const popularNews = 5;

    const url = `${apiUrl}api/v1/contents/detail/${contentsId}?recentNews=${recentNews}&popularNews=${popularNews}`;
    const categoryMapping = {
        'issue': '디시이슈',
        'interview': '디시인터뷰',
    };
    const categoryEndpoint = categoryMapping[categoryId] || 'dc-issue';

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const data = await apiRequest(url, {}, 'GET');
                setEmotion(data.data.emotions);
                setContents(data.data.contentDetail);
                setResentList(data.data.resents);
                setPopularsList(data.data.populars);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };
        if (contentsId) {
            fetchNews();
        }// eslint-disable-next-line
    }, [contentsId]); // contentsId만 의존성 배열에 넣습니다.



    const sendEmotion = async (type) => {
        try {
            const url = `${apiUrl}api/v1/emotions/save-and-return-summary`;
            const params = {
                "emotionType": type,
                "entityType": "CONTENT",
                "entityId": contentsId
            };
            const response = await apiRequest(url, params, 'POST');
            setEmotion(response.data);
        } catch (error) {
            console.error('Error sending emotion:', error);
        }
    };

    const toggleFontSizeBoxVisibility = () => {
        setIsFontSizeBoxVisible(prevState => !prevState);
    };
    const closeFontSizeBox = () => {
        setIsFontSizeBoxVisible(false);
    };
    const toggleShareBoxVisibility = () => {
        setIsShareBoxVisible(prevState => !prevState);
    };
    const closeShareBox = () => {
        setIsShareBoxVisible(false);
    };

    const handleFontSizeChange = (size) => {
        setSelectedFontSize(size);
        closeFontSizeBox();
    };

    return (
        <>
            <StickyNewsDetail title={decodeHtmlEntities(contents.title)} onShareClick={toggleShareBoxVisibility} onFontClick={toggleFontSizeBoxVisibility} />
            <section className="contentsWrap">
                <div className="newsLayout">
                    <div className="leftWrap">
                        <div className="articleWrap">
                            <div className="category ftSz16">{categoryEndpoint}</div>
                            <div className="articleTitle ftSz36">{contents.title ? decodeHtmlEntities(contents.title) : <Skeleton width="100%" height={46} />}</div>
                            <div className="info">
                                {contents.authors && (
                                    <ul className="publisher">{contents.authors} 기자</ul>
                                )}
                                <ul className="date">
                                    {contents && contents.publishedAt && (
                                        <>입력 {formatDate(contents.publishedAt)}</>
                                    )}
                                </ul>
                                <ul className="btn">
                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_share.svg" onClick={toggleShareBoxVisibility} alt=""/>
                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_fontsize.svg" onClick={toggleFontSizeBoxVisibility} alt=""/>
                                </ul>
                            </div>
                            <div className="layerBox">
                                {isShareBoxVisible && (
                                    <div className="box share">
                                        <div className="tit">공유</div>
                                        <div className="close" onClick={closeShareBox}>
                                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <div className="cont">
                                            <ul onClick={copyUrlToClipboard}>
                                                <li><div className="icoUrl"></div></li>
                                                <li>URL복사</li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <KakaoShareButton
                                                        title={decodeHtmlEntities(contents.title)}
                                                        description={htmlToText(contents.content,100)}
                                                        imageUrl={contents.thumbnail}
                                                        linkUrl={currentUrl}
                                                    />
                                                </li>
                                                <li>카카오톡</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {isFontSizeBoxVisible && (
                                    <div className="box font">
                                        <div className="tit">글자 크기 설정</div>
                                        <div className="close" onClick={closeFontSizeBox}>
                                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <div className="cont">
                                            <ul
                                                className={`ftSz16 ${selectedFontSize === 'ftSz16' ? 'on' : ''}`}
                                                onClick={() => handleFontSizeChange('ftSz16')}
                                            >
                                                <li>가</li>
                                                <li>작게</li>
                                            </ul>
                                            <ul
                                                className={`ftSz18 ${selectedFontSize === 'ftSz18' ? 'on' : ''}`}
                                                onClick={() => handleFontSizeChange('ftSz18')}
                                            >
                                                <li>가</li>
                                                <li>보통</li>
                                            </ul>
                                            <ul
                                                className={`ftSz20 ${selectedFontSize === 'ftSz20' ? 'on' : ''}`}
                                                onClick={() => handleFontSizeChange('ftSz20')}
                                            >
                                                <li>가</li>
                                                <li>크게</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="contents edt">
                                <div className={`break-words content ${selectedFontSize}`}>
                                    <div dangerouslySetInnerHTML={{ __html: contents.content }} />
                                </div>
                            </div>
                            {contents.tags && contents.tags.length > 0 && (
                                <div className="tag">
                                    {contents.tags.map((keyword, index) => (
                                        <ul key={index}>#{keyword.tag}</ul>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="trendWidgetWrap">
                            <iframe
                                src="https://dev.dctrend.newshub.kr/widget/type_a.html"
                                width="100%"
                                height="262"
                                title="trendWidgetB"
                                style={{ border: 'none' }}
                            ></iframe>
                        </div>
                        <div className="sectionTitleSub">
                            <div className="title">이 기사, 어떠셨나요?</div>
                        </div>
                        <div className="newsVote">
                            {emotions.map((item, index) => (
                                <ul key={index} onClick={() => sendEmotion(item.emotionType)}>
                                    <li><img src={item.iconUrl} alt={item.name} /></li>
                                    <li>{item.name}</li>
                                    <li>{item.count}</li>
                                </ul>
                            ))}
                        </div>
                        <CommentWrap/>
                    </div>
                    <div className="rightWrap">
                        <div className="rightSticky">
                            <div className="dcTrend">
                                <div className="dcTitle">실시간 인기투표<div className="arw s24"></div></div>
                                <Swiper
                                    direction='vertical'
                                    slidesPerView='auto'
                                    spaceBetween={0}
                                    touchReleaseOnEdges={true}
                                    breakpoints={{
                                        480: {
                                            direction: 'horizontal',
                                            slidesPerView: 'auto',
                                            spaceBetween: 16,
                                        },
                                        1200: {
                                            direction: 'vertical',
                                            slidesPerView: 'auto',
                                            spaceBetween: 0,
                                        }
                                    }}
                                >
                                    <SwiperSlide>
                                        <ul className="hoverImgPt">
                                            <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                                            <li className="info">
                                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                                <dl className="title">아이돌 걸그룹 랭킹</dl>
                                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                            </li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ul className="hoverImgPt">
                                            <li className="thumb"><img src="https://cdn.hankyung.com/photo/202404/BF.36389450.1.jpg" alt=""/></li>
                                            <li className="info">
                                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                                <dl className="title">아이돌 보이그룹 랭킹</dl>
                                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                            </li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ul className="hoverImgPt">
                                            <li className="thumb"><img src="https://file2.nocutnews.co.kr/newsroom/image/2022/01/31/202201312047237692_0.jpg" alt=""/></li>
                                            <li className="info">
                                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                                <dl className="title">트로트 가수 남성 랭킹</dl>
                                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                            </li>
                                        </ul>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="stickyTitle">실시간 인기기사</div>
                            <div className="popularNewsRight">
                                {popularsList.map((item,index) => (
                                    <Link key={index} to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <ul key={index}>
                                            <li>{index + 1}</li>
                                            <li>{decodeHtmlEntities(item.title)}</li>
                                            <li>
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"; }}
                                                />
                                            </li>
                                        </ul>
                                    </Link>
                                ))}
                            </div>
                            <div className="stickyTitle">최신 기사</div>
                            <div className="rtNewsRight">
                                {resentList.map(item => (
                                    <ul key={item.newsId}>
                                        <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                            <li>
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"; }}
                                                />
                                            </li>
                                            <li>{decodeHtmlEntities(item.title)}</li>
                                        </Link>
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContentsDetail;