import React, {useEffect} from 'react';
import StickyDefault from "../layout/stickyDefault";
import ChartRank from "../../component/chartRank";
import {Link} from "react-router-dom";
import ChartMini from "../../component/chartmini";
import ChartKeyword from "../../component/chartKeyword";

function generateRandomData(size, min, max) {
    return Array.from({ length: size }, () => Math.floor(Math.random() * (max - min + 1)) + min);
}

// 차트 데이터를 설정합니다.
const dataPoints = {
    labels: ['뉴진스', '에스파', 'IVE', 'QWER', '아일릿', 'G-IDLE', '르세라핌', 'KISS OF LIFE', '트리플에스', '베이비몬스터'],
    datasets: [{
        data: [139000, 125000, 113000, 87500, 70500, 66000, 43000, 39500, 28000, 24000],
        borderWidth: 0,
        backgroundColor: '#2D65F2',
        barThickness: window.innerWidth < 480 ? 10 : 16,
        borderRadius: 50
    }]
};

const dataKeyword = [
    { x: '2024-06-29', y: 30000 },
    { x: '2024-06-30', y: 32000 },
    { x: '2024-07-01', y: 31000 },
    { x: '2024-07-02', y: 29000 },
    { x: '2024-07-03', y: 30000 },
    { x: '2024-07-04', y: 32000 },
    { x: '2024-07-05', y: 31000 },
    { x: '2024-07-06', y: 50000 },
    { x: '2024-07-07', y: 25000 },
    { x: '2024-07-08', y: 24000 },
    { x: '2024-07-09', y: 23000 },
    { x: '2024-07-10', y: 22000 },
    { x: '2024-07-11', y: 21000 },
    { x: '2024-07-12', y: 22000 },
    { x: '2024-07-13', y: 21000 },
    { x: '2024-07-14', y: 23000 },
    { x: '2024-07-15', y: 24000 },
    { x: '2024-07-16', y: 25000 },
    { x: '2024-07-17', y: 27000 },
    { x: '2024-07-18', y: 29000 },
    { x: '2024-07-19', y: 28000 },
    { x: '2024-07-20', y: 30000 },
    { x: '2024-07-21', y: 31000 },
    { x: '2024-07-22', y: 29000 },
    { x: '2024-07-23', y: 27000 },
    { x: '2024-07-24', y: 25000 },
    { x: '2024-07-25', y: 27000 },
    { x: '2024-07-26', y: 26000 },
    { x: '2024-07-27', y: 28000 },
    { x: '2024-07-28', y: 100000 }
];

const miniChartData = generateRandomData(30, 100, 1000);

function RankChart() {

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="rankingMain">
                    <div className="mainTitle">
                        <ul className="tit">트렌드 연예인</ul>
                        <ul className="update">24.04.08 오후 6시 5분</ul>
                        <ul className="info"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt=""/>랭킹은 트렌드 지수를 기준으로 산정되며, 트렌드 지수는 연예인에 대한 온라인 관심도와 활동을 반영합니다.</ul>
                    </div>
                    <div className="chartWrap">
                        <ChartRank dataPoints={dataPoints} />
                    </div>
                    <div className="chartTableRt">
                        <div className="chartTable">
                            <ul className="tr head">
                                <li className="rank">순위</li>
                                <li className="name">이름</li>
                                <li className="cate">카테고리</li>
                                <li className="index">트랜드지수</li>
                                <li className="chart">지수 차트 (최근 30일)</li>
                                <li className="btnBox">투표하기</li>
                            </ul>
                            <ul className="tr">
                                <li className="rank"><div className="num">1</div><div className="rt1"><span>위</span> 뉴진스</div><div className="rankMark same">0</div></li>
                                <li className="name">뉴진스</li>
                                <li className="cate"><div className="rt2">카테고리</div>가수</li>
                                <li className="index"><div className="rt2">트렌드지수</div>69,330</li>
                                <li className="chart"><div className="rt2">지수차트</div><div className="miniChart"><ChartMini data={miniChartData}/></div><div className="drop open"></div></li>
                                <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                            </ul>
                            <div className="detail">
                                <div className="layerBox">
                                    <div className="layerTitle"><span>1위</span>뉴진스</div>
                                    <div className="close"><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                                    <div className="scrollBox">
                                        <div className="inBox">
                                            <div className="left">
                                                <div className="tit">키워드</div>
                                                <div className="openChart"><ChartKeyword dataPoints={dataKeyword} /></div>
                                            </div>
                                            <div className="right">
                                                <div className="box">
                                                    <div className="tit">성별</div>
                                                    <div className="gender">
                                                        <dl className="male">
                                                            <dt>남성</dt>
                                                            <dt>44.34%</dt>
                                                        </dl>
                                                        <dl className="female">
                                                            <dt>여성</dt>
                                                            <dt>55.66%</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div className="box">
                                                    <div className="tit">연령</div>
                                                    <div className="age">
                                                        <dl>
                                                            <dt>10대</dt>
                                                            <dt>11%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>20대</dt>
                                                            <dt>11%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>30대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>40대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>50대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>60대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="tr">
                                <li className="rank"><div className="num">2</div><div className="rt1"><span>위</span> 에스파</div><div className="rankMark down">1</div></li>
                                <li className="name">에스파</li>
                                <li className="cate"><div className="rt2">카테고리</div>가수</li>
                                <li className="index"><div className="rt2">트렌드지수</div>69,330</li>
                                <li className="chart"><div className="rt2">지수차트</div><div className="miniChart"><ChartMini data={miniChartData}/></div><div className="drop"></div></li>
                                <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                            </ul>
                            <ul className="tr">
                                <li className="rank"><div className="num">1000</div><div className="rt1"><span>위</span> IVE</div><div className="rankMark up">999+</div></li>
                                <li className="name">IVE</li>
                                <li className="cate"><div className="rt2">카테고리</div>가수</li>
                                <li className="index"><div className="rt2">트렌드지수</div>69,330</li>
                                <li className="chart"><div className="rt2">지수차트</div><div className="miniChart"><ChartMini data={miniChartData}/></div><div className="drop"></div></li>
                                <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default RankChart;