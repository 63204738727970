import React, { useEffect, useState } from "react";
import StickyDefault from "../layout/stickyDefault";
import { Checkbox } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authAtom';
import {Link, useNavigate} from "react-router-dom";
import { useAppContext  } from '../../component/context';
import { useApi } from '../../js/module/api';
import Swal from 'sweetalert2';

function Withdraw() {
    const auth = useRecoilValue(authState);
    const setAuthState = useSetRecoilState(authState);
    const navigate = useNavigate();
    const { naverClientID,naverClientSecret } = useAppContext();
    const [checkedItems, setCheckedItems] = useState({});
    const [isAgreed, setIsAgreed] = useState(false);
    const { apiRequest } = useApi();

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const handleCheckboxChange = (e) => {
        setCheckedItems(prev => ({ ...prev, [e.target.id]: e.target.checked }));
    };

    const handleAgreeChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleClick = () => {
        const social = auth.user.type;
        switch (social) {
            case 'kakao':
                withdrawKakao();
                break;
            case 'naver':
                withdrawNaver();
                break;
            case 'google':
                withdrawGoogle();
                break;
            default:
                return;
        }
    };

    const withdrawKakao = async () => {
        const accessToken = auth.user.accessToken;

        try {
            const data = await apiRequest(
                'https://kapi.kakao.com/v1/user/unlink',
                {},
                'POST',
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${accessToken}`,
                },
                'replace'
            );
            console.log('Unlink success:', data);
            withdrawDctrend();
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    const withdrawGoogle = async () => {
        try {
            const accessToken = auth.user.accessToken;
            const revokeUrl = `https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`;

            await apiRequest(
                revokeUrl,
                {},
                'POST',
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                'replace'
            );
            console.log('Google account access revoked');
            withdrawDctrend();
        } catch (error) {
            console.error('Error during account deletion:', error);
        }
    };

    const withdrawNaver = async () => {
        try {
            const clientId = naverClientID;
            const clientSecret = naverClientSecret;
            const accessToken = auth.user.accessToken;
            const url = `https://nid.naver.com/oauth2.0/token?grant_type=revoke&client_id=${clientId}&client_secret=${clientSecret}&access_token=${accessToken}&service_provider=NAVER`;

            await apiRequest(
                url,
                {},
                'GET',
                {},
                'replace'
            );

            console.log('naver unlink success');
            withdrawDctrend();
        } catch (error) {
            console.error('Error during Naver account deletion:', error);
        }
    };

    const withdrawDctrend = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('user');
        localStorage.removeItem('com.naver.nid.oauth.state_token');
        localStorage.removeItem('com.naver.nid.access_token');
        localStorage.removeItem('kakao_3dc1788d2fcbc63efea81a1facf87909');
        setAuthState({ isAuthenticated: false, token: null, user: null });
        Swal.fire({
            icon: 'success',
            text: '회원탈퇴가 완료되었습니다.',
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/');
            }
        });
    };

    const isButtonEnabled = () => {
        const checkboxesChecked = Object.keys(checkedItems).some(id => checkedItems[id]);
        return checkboxesChecked && isAgreed;
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <div className="myPage">
                    <div className="topTitle">디시트렌드 탈퇴</div>
                    <div className="withdrawBox">
                        <div className="rBox form">
                            <div className="title">어떤 부분이 불편하셨나요?<div className="req">필수</div></div>
                            <div className="list">
                                <ul>
                                    <li>서비스 이용 관련</li>
                                    <li>
                                        <dl>
                                            <Checkbox id="ck1" onChange={handleCheckboxChange}>서비스 사용 빈도가 낮음.</Checkbox>
                                        </dl>
                                        <dl>
                                            <Checkbox id="ck2" onChange={handleCheckboxChange}>원하는 투표가 없음.</Checkbox>
                                        </dl>
                                        <dl>
                                            <Checkbox id="ck3" onChange={handleCheckboxChange}>제공되는 뉴스 콘텐츠가 만족스럽지 않음.</Checkbox>
                                        </dl>
                                        <dl>
                                            <Checkbox id="ck4" onChange={handleCheckboxChange}>랭킹 정보에 신뢰가 가지 않음.</Checkbox>
                                        </dl>
                                    </li>
                                </ul>
                                <ul>
                                    <li>기술적 문제</li>
                                    <li>
                                        <dl>
                                            <Checkbox id="ck5" onChange={handleCheckboxChange}>빈번한 오류가 발생함.</Checkbox>
                                        </dl>
                                        <dl>
                                            <Checkbox id="ck6" onChange={handleCheckboxChange}>소셜 로그인에 문제가 발생함.</Checkbox>
                                        </dl>
                                    </li>
                                </ul>
                                <ul>
                                    <li>사용자 경험 관련</li>
                                    <li>
                                        <dl>
                                            <Checkbox id="ck7" onChange={handleCheckboxChange}>투표 참여 방법이 복잡함.</Checkbox>
                                        </dl>
                                        <dl>
                                            <Checkbox id="ck8" onChange={handleCheckboxChange}>모바일 환경에서의 이용이 불편함.</Checkbox>
                                        </dl>
                                    </li>
                                </ul>
                                <ul>
                                    <li>개인정보 및 보안 관련</li>
                                    <li>
                                        <dl>
                                            <Checkbox id="ck9" onChange={handleCheckboxChange}>개인정보 보호가 우려됨.</Checkbox>
                                        </dl>
                                        <dl>
                                            <Checkbox id="ck10" onChange={handleCheckboxChange}>소셜 로그인으로 인한 개인정보 유출이 우려됨.</Checkbox>
                                        </dl>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="rBox info">
                            <div className="title">아래 내용을 반드시 확인해 주세요.</div>
                            <div className="useInfo">
                                <ul>
                                    <li>디시트렌드 탈퇴 시 처리 내용</li>
                                    <li>
                                        <dl>보유중인 무료 투표권은 소멸됩니다.</dl>
                                        <dl>결제일로부터 1년 이내에 구매한 투표권에 대해서는 원 결제 수단(카드)로 환불 처리되며, 1년이 넘은 거래에 대해서는 계좌 이체 또는 기타 적절한 방법으로 환불 처리됩니다.</dl>
                                        <dl>환불 절차는 회원탈퇴 신청 접수 후 7영업일 이내에 완료됩니다.</dl>
                                    </li>
                                </ul>
                                <ul>
                                    <li>탈퇴 시 투표 내역 관리</li>
                                    <li>
                                        <dl>디시트렌드 탈퇴 시, 모든 개인 정보 및 투표 내역이 삭제되며, 이는 복구할 수 없습니다.</dl>
                                    </li>
                                </ul>
                                <ul>
                                    <li>탈퇴 후 재가입 규정</li>
                                    <li>
                                        <dl>디시트렌드 탈퇴 후 재가입하더라도 기존의 투표권은 이미 환불 절차가 진행되었기 때문에 양도되지 않습니다.</dl>
                                    </li>
                                </ul>
                            </div>
                            <div className="feedback"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt="" /> 탈퇴관련 문의는 피드백센터를 이용해주세요.</div>
                            <div className="agree">
                                <Checkbox id="agree" onChange={handleAgreeChange}>위 내용을 모두 확인하였습니다.</Checkbox>
                                <div className="req">필수</div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button onClick={handleClick} disabled={!isButtonEnabled()}>탈퇴하기</button>
                            <Link to="/"><ul className="cancel">취소</ul></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Withdraw;
