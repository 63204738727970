import StickyDefault from "../layout/stickyDefault";
import React from "react";

function OrderTicket() {
    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="myPage">
                    <div className="topTitle">투표권 구매</div>
                    <div className="ticketOrder">
                        <div className="detail">
                            <ul>
                                <li>보유 투표권</li>
                                <li>10개</li>
                            </ul>
                            <ul>
                                <li>구매 투표권</li>
                                <li>10개<div>1,000원</div></li>
                            </ul>
                        </div>
                        <ul className="price">
                            <li>최종 결제금액</li>
                            <li><span>1,000</span>원</li>
                        </ul>
                        <div className="btn">구매하기</div>
                    </div>

                    <div className="useInfo">
                        <ul>
                            <li>투표권 이용안내</li>
                            <li>
                                <dl>투표권 구매에는 부가가치세가 부과되지 않습니다.</dl>
                                <dl>투표권 구매 또는 사용 전 이용약관 동의가 필요합니다.</dl>
                                <dl>결제 상세내역은 디시트렌드 > 내 투표권 페이지에서 확인 가능합니다.</dl>
                                <dl>투표권은 무료 투표권부터 사용되며, 이후 먼저 구매한 투표권 순서로 사용됩니다.</dl>
                                <dl>충전한 투표권을 사용하여 디시트렌드의 다양한 투표에 참여할 수 있습니다.</dl>
                                <dl>무료 투표권은 매월 1일 00시 00분에 5개씩 지급되며, 이월되지 않습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>구매취소</li>
                            <li>
                                <dl>디시트렌드에서 구매한 투표권 패키지는 사용하지 않은 경우에 한해, 구매 후 7일 이내에 마이페이지에서 직접 구매 취소할 수 있습니다.</dl>
                                <dl>사용한 투표권은 구매 취소 및 환불이 불가합니다.</dl>
                                <dl>무료 투표권은 구매 취소 및 환불 대상이 아닙니다. 따라서 충전 내역과 취소 내역의 투표권 수량이 다를 수 있습니다.</dl>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
        </>
    );
}

export default OrderTicket;