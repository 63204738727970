import StickyDefault from "../layout/stickyDefault";
import React, {useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Navigation, Pagination} from "swiper/modules";
import {Link} from "react-router-dom";
import SortSelect from "../../component/sortSelect";

function Awards() {

    const [selectedSort, setSelectedSort] = useState('latest');

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="awardsTop">
                    <Swiper
                        modules={[Navigation,Pagination]}
                        slidesPerView="auto"
                        spaceBetween={0}
                        pagination={{
                            el: ".swiper-pagination",
                            type: "fraction",
                        }}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }}
                        className="awardsRoll"
                    >
                        <SwiperSlide>
                            <div className="item">
                                <ul className="left">
                                    <div className="box">
                                        <li className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></li>
                                        <li className="txt"><div>✨</div><div className="b">1위</div> <div>뉴진스</div><div>✨</div></li>
                                    </div>
                                </ul>
                                <ul className="right">
                                    <li className="date">24.06.14~24.06.30</li>
                                    <li className="title">아이돌 걸그룹 랭킹</li>
                                    <li className="info">
                                        <dl className="rank">1위</dl>
                                        <dl className="name">뉴진스</dl>
                                        <dl className="score">1,564,761표</dl>
                                    </li>
                                    <Link to="/vote/awardsDetail/1"><li className="btn">전체 순위 보기</li></Link>
                                </ul>
                                <ul className="mask"></ul>
                                <ul className="bg"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></ul>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <ul className="left">
                                    <div className="box">
                                        <li className="thumb"><img src="https://image.xportsnews.com/contents/images/upload/article/2022/0601/mb_1654035768498112.jpg" alt=""/></li>
                                        <li className="txt"><div>✨</div><div className="b">1위</div> <div>진</div><div>✨</div></li>
                                    </div>
                                </ul>
                                <ul className="right">
                                    <li className="date">24.06.14~24.06.30</li>
                                    <li className="title">아이돌 남성 랭킹</li>
                                    <li className="info">
                                        <dl className="rank">1위</dl>
                                        <dl className="name">진</dl>
                                        <dl className="score">1,564,761표</dl>
                                    </li>
                                    <Link to="/vote/awardsDetail/1"><li className="btn">전체 순위 보기</li></Link>
                                </ul>
                                <ul className="mask"></ul>
                                <ul className="bg"><img src="https://image.xportsnews.com/contents/images/upload/article/2022/0601/mb_1654035768498112.jpg" alt=""/></ul>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                </div>

                <div className="gatherVote">
                    <div className="gatherList">
                        <div className="voteTitle">종료된 투표</div>
                        <SortSelect
                            options={[
                                { id: 'latest', label: '최신순' },
                                { id: 'views', label: '조회수순' },
                            ]}
                            selectedSort={selectedSort}
                            onSortChange={setSelectedSort}
                        />
                        <div className="tab ended">
                            <ul className="on">전체</ul>
                            <ul>연예인</ul>
                            <ul>유튜버</ul>
                            <ul>스포츠인</ul>
                        </div>
                        <div className="list ended">
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://i.namu.wiki/i/ZnBMAAGJaiFKqDmASXCt-977Xuq6gLA-G8AsD4K1BKCVBEzrjISoW9QyfcSKPnacwuBpCGSSyBtCJv8E-UocNQ.webp" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">아이돌 남성 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://i.namu.wiki/i/WPFg1MSbEwPuKAj3-YYv1AyRGeegupuueKk17fgiWJEZpn3RqOdAk6tZlsQ7rJUcab9WqEkDpgVwI5IoGYYk-A.webp" alt=""/></div>
                                    <div className="name">진</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://flexible.img.hani.co.kr/flexible/normal/800/450/imgdb/original/2023/0109/20230109503590.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">한국 여성 골퍼 순위</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://images.khan.co.kr/article/2022/12/28/news-p.v1.20221228.7980909af2024fbb8caf2a214bb67477_P1.jpg" alt=""/></div>
                                    <div className="name">유현주</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://spnimage.edaily.co.kr/images/photo/files/NP/S/2017/07/PS17072200023.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">보디빌딩 유튜버 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://i.namu.wiki/i/jjADJ6h0Zr5BBEh1eKWz2p9kNFchuWRWl_bWWwxNirvztoDKfpZuvYIFEfAbJUW81W0Sr2lB5UgohFFYW1iirw.webp" alt=""/></div>
                                    <div className="name">지피티</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://cdn.mediatoday.co.kr/news/photo/202402/316275_443634_08.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">아이돌 여성 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://i.namu.wiki/i/gJ9E3yHA7x1q72mqv2xFo1GkIvUU5bF95Uzxlx1HzT9qUhLFlcpcmj7Xajy174jj_S-XPbZ13c2DBQ1K6RwooQ.webp" alt=""/></div>
                                    <div className="name">카리나</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://png.pngtree.com/thumb_back/fw800/background/20230411/pngtree-football-player-shooting-pose-flame-effect-advertising-background-image_2283569.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">세계 축구선수 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202308/13/3756de8c-1ea6-4988-b063-25f26d9b76d5.jpg" alt=""/></div>
                                    <div className="name">손흥민</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://res.heraldm.com/content/image/2021/09/26/20210926000264_0.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">트로트 가수 여성 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://thumb.mtstarnews.com/06/2021/02/2021021917360769825_1.jpg" alt=""/></div>
                                    <div className="name">은가은</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://cdn.ardentnews.co.kr/news/photo/202310/1550_5814_2358.png" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">먹방 유튜버 순위</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://yt3.googleusercontent.com/sL5ugPfl9vvwRwhf6l5APY__BZBw8qWiwgHs-uVsMPFoD5-a4opTJIcRSyrY8aY5LEESOMWJ=s900-c-k-c0x00ffffff-no-rj" alt=""/></div>
                                    <div className="name">히밥</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://pickcon.co.kr/site/data/img_dir/2022/12/01/2022120180065_0.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">배우 여성 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://image.cine21.com/resize/cine21/person/2019/1120/14_57_09__5dd4d5b51cd71[X252,310].jpg" alt=""/></div>
                                    <div className="name">이영애</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://i.namu.wiki/i/e-QmywxRwR_zVXLEB7vta63d8JV7clet6DTPyAQW4kWSTzwM-HjatNJyuPE9EDBLM2uwJH9A8X-ncNR3tB7pfQ.webp" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">3세대 레전드 아이돌 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://cdn.hankyung.com/photo/202304/01.33189797.1.jpg" alt=""/></div>
                                    <div className="name">IVE</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://cdnimg.melon.co.kr/resource/image/cds/musicstory/imgUrl20171103060747001.jpg/melon/optimize/90" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">2세대 레전드 아이돌 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://db.kookje.co.kr/news2000/photo/2022/0811/L20220811.22014002646i1.jpg" alt=""/></div>
                                    <div className="name">소녀시대</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://img.sbs.co.kr/newsnet/etv/upload/2018/12/17/30000619470_700.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">락(ROCK) 가수 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://img.sbs.co.kr/newsnet/etv/upload/2018/12/17/30000619470_700.jpg" alt=""/></div>
                                    <div className="name">데이식스</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                            <ul className="hoverImgPt">
                                <Link to="/vote/awardsDetail/1">
                                <li className="thumb"><img src="https://pds.joongang.co.kr//news/component/htmlphoto_mmdata/201709/28/8c8a32cf-2b07-46a6-9ad2-1eb50487e997.jpg" alt=""/></li>
                                <li className="info">
                                    <dl className="date">23.06.14 ~ 24.06.30</dl>
                                    <dl className="title">7080 발라드의 전설 남성 랭킹</dl>
                                </li>
                                <div className="result">
                                    <div className="rank">1위</div>
                                    <div className="rankThumb"><img src="https://yt3.googleusercontent.com/im_HWH6dUamemCeez52-liQOWgNBJSkWma7eqffDWnFcxVQCyy2P5xx0llxV1dQmCy69QkunzQg=s900-c-k-c0x00ffffff-no-rj" alt=""/></div>
                                    <div className="name">조용필</div>
                                    <div className="score">72,081점</div>
                                </div>
                                </Link>
                            </ul>
                        </div>
                        <div className="listMoreWrap">
                            <div className="btnListMore">더보기<img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_arw_down_white.svg" alt=""/></div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Awards;