import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useAppContext } from '../../component/context';

function NewsSubMenu() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { categoryList } = useAppContext();

    const getLinkClassName = (path) => {
        const [linkPath, linkSearch] = path.split('?');
        const linkQueryParams = new URLSearchParams(linkSearch || '');

        return location.pathname === linkPath && [...linkQueryParams.entries()].every(([key, value]) => queryParams.get(key) === value) ? "on" : "";
    };

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <div className="newsSubMenu">
            <ul className="menu">
                <li className="tit">트렌드 뉴스</li>
                <li className="list on">
                    <Link to="/news/trend">
                        <dl className={getLinkClassName("/news/trend")}>전체</dl>
                    </Link>
                    {categoryList.map(item => (
                        <Link key={item.id} to={`/news/trend/${item.code}/1`}>
                            <dl className={isActive(`/news/trend/${item.code}`) ? "on" : ""}>{item.name}</dl>
                        </Link>
                    ))}
                </li>
            </ul>
        </div>
    );
}

export default NewsSubMenu;
