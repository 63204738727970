import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import React from "react";
import StickyDefault from "../layout/stickyDefault";

function Youtuber() {
    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="rankingMain">
                    <div className="mainTitle">
                        <ul className="tit">트렌드 연예인</ul>
                        <ul className="update">24.04.08 오후 6시 5분</ul>
                        <ul className="info"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt=""/>랭킹은 트렌드 지수를 기준으로 산정되며, 트렌드 지수는 연예인에 대한 온라인 관심도와 활동을 반영합니다.</ul>
                    </div>
                    <div className="rankingBox">
                        <div className="box">
                            <div className="top">
                                <ul className="tit">가수</ul>
                                <ul className="sort">
                                    <li className="on">일간</li>
                                    <li>주간</li>
                                </ul>
                            </div>
                            <div className="list">
                                <ul>
                                    <li className="rank">
                                        <dl>1</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">뉴진스</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>2</dl>
                                        <dl className="rankMark up">2</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">에스파</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>3</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/dbYaykrNkUTz5RZOfjwQjU38vmA6zORYe_H60qH17Sl_PJ-oXcqvGPyuyJu6HKcDJuMqQdq--y6JPabC0QZzDA.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">IVE</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>4</dl>
                                        <dl className="rankMark new">new</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://phinf.wevpstatic.net/MjAyMzEwMTNfOTcg/MDAxNjk3MTc4NTMyOTA2.YcfBd9JEgr4AG6ae5CxXQlHFZoyz0Gsbsd28r9gViZsg.ufxCBuSFybk-1d8KlB7a3WHGoQNB-JnN_OlsJC2nmUcg.JPEG/bfb0f355-9e7d-4c83-a030-814c6fe53c7b.jpeg?type=w670" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">QWER</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>5</dl>
                                        <dl className="rankMark down">3</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/KjP897v1S9ZJsJcWefRGWNXEpZpmqjeAwP-VQOUA8cZRe8lSlfW6_gPoobenfltRC0Oa92dEN61t42prA50CbA.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">아일릿</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>6</dl>
                                        <dl className="rankMark up">1</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://nolae.eu/cdn/shop/articles/gi-dle-beginnt-2024-mit-dem-zweiten-studio-album-2-889229_1200x732.jpg?v=1704927358" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">G-IDLE</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>7</dl>
                                        <dl className="rankMark up">5</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202306/04/138bdfca-3e86-4c09-9632-d22df52a0484.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">가수<div className="dot"></div>여성</dt>
                                            <dt className="name">아이유</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>8</dl>
                                        <dl className="rankMark up">4</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/AF9SpVUNbbSFcOjRXIsyr29SxlZqhDX2Ui_dP04_VUYV98bC0t4bwkOs3Ec5FgNn6UcysEatLP5OYtsXTOzlkQ.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">가수<div className="dot"></div>남성</dt>
                                            <dt className="name">지코</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>9</dl>
                                        <dl className="rankMark down">2</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://img1.newsis.com/2023/01/06/NISI20230106_0001170846_web.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">르세라핌</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>10</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2022/11/29/479c1886-acb7-4416-963b-24baf1888c78.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">카라</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                            </div>
                            <div className="btn">
                                <Link to="/ranking/chart"><ul className="more">랭킹 더보기</ul></Link>
                                <Link to="/vote/list"><ul className="vote">투표하기</ul></Link>
                            </div>
                        </div>
                        <div className="box">
                            <div className="top">
                                <ul className="tit">배우</ul>
                                <ul className="sort">
                                    <li className="on">일간</li>
                                    <li>주간</li>
                                </ul>
                            </div>
                            <div className="list">
                                <ul>
                                    <li className="rank">
                                        <dl>1</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">뉴진스</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>2</dl>
                                        <dl className="rankMark up">2</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">에스파</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>3</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/dbYaykrNkUTz5RZOfjwQjU38vmA6zORYe_H60qH17Sl_PJ-oXcqvGPyuyJu6HKcDJuMqQdq--y6JPabC0QZzDA.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">IVE</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>4</dl>
                                        <dl className="rankMark new">new</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://phinf.wevpstatic.net/MjAyMzEwMTNfOTcg/MDAxNjk3MTc4NTMyOTA2.YcfBd9JEgr4AG6ae5CxXQlHFZoyz0Gsbsd28r9gViZsg.ufxCBuSFybk-1d8KlB7a3WHGoQNB-JnN_OlsJC2nmUcg.JPEG/bfb0f355-9e7d-4c83-a030-814c6fe53c7b.jpeg?type=w670" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">QWER</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>5</dl>
                                        <dl className="rankMark down">3</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/KjP897v1S9ZJsJcWefRGWNXEpZpmqjeAwP-VQOUA8cZRe8lSlfW6_gPoobenfltRC0Oa92dEN61t42prA50CbA.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">아일릿</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>6</dl>
                                        <dl className="rankMark up">1</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://nolae.eu/cdn/shop/articles/gi-dle-beginnt-2024-mit-dem-zweiten-studio-album-2-889229_1200x732.jpg?v=1704927358" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">G-IDLE</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>7</dl>
                                        <dl className="rankMark up">5</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202306/04/138bdfca-3e86-4c09-9632-d22df52a0484.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">가수<div className="dot"></div>여성</dt>
                                            <dt className="name">아이유</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>8</dl>
                                        <dl className="rankMark up">4</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/AF9SpVUNbbSFcOjRXIsyr29SxlZqhDX2Ui_dP04_VUYV98bC0t4bwkOs3Ec5FgNn6UcysEatLP5OYtsXTOzlkQ.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">가수<div className="dot"></div>남성</dt>
                                            <dt className="name">지코</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>9</dl>
                                        <dl className="rankMark down">2</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://img1.newsis.com/2023/01/06/NISI20230106_0001170846_web.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">르세라핌</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>10</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2022/11/29/479c1886-acb7-4416-963b-24baf1888c78.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">카라</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                            </div>
                            <div className="btn">
                                <ul className="more">랭킹 더보기</ul>
                                <ul className="vote">투표하기</ul>
                            </div>
                        </div>
                        <div className="box">
                            <div className="top">
                                <ul className="tit">방송인</ul>
                                <ul className="sort">
                                    <li className="on">일간</li>
                                    <li>주간</li>
                                </ul>
                            </div>
                            <div className="list">
                                <ul>
                                    <li className="rank">
                                        <dl>1</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">뉴진스</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>2</dl>
                                        <dl className="rankMark up">2</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">에스파</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>3</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/dbYaykrNkUTz5RZOfjwQjU38vmA6zORYe_H60qH17Sl_PJ-oXcqvGPyuyJu6HKcDJuMqQdq--y6JPabC0QZzDA.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">IVE</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>4</dl>
                                        <dl className="rankMark new">new</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://phinf.wevpstatic.net/MjAyMzEwMTNfOTcg/MDAxNjk3MTc4NTMyOTA2.YcfBd9JEgr4AG6ae5CxXQlHFZoyz0Gsbsd28r9gViZsg.ufxCBuSFybk-1d8KlB7a3WHGoQNB-JnN_OlsJC2nmUcg.JPEG/bfb0f355-9e7d-4c83-a030-814c6fe53c7b.jpeg?type=w670" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">QWER</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>5</dl>
                                        <dl className="rankMark down">3</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/KjP897v1S9ZJsJcWefRGWNXEpZpmqjeAwP-VQOUA8cZRe8lSlfW6_gPoobenfltRC0Oa92dEN61t42prA50CbA.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">아일릿</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>6</dl>
                                        <dl className="rankMark up">1</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://nolae.eu/cdn/shop/articles/gi-dle-beginnt-2024-mit-dem-zweiten-studio-album-2-889229_1200x732.jpg?v=1704927358" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">G-IDLE</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>7</dl>
                                        <dl className="rankMark up">5</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202306/04/138bdfca-3e86-4c09-9632-d22df52a0484.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">가수<div className="dot"></div>여성</dt>
                                            <dt className="name">아이유</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>8</dl>
                                        <dl className="rankMark up">4</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://i.namu.wiki/i/AF9SpVUNbbSFcOjRXIsyr29SxlZqhDX2Ui_dP04_VUYV98bC0t4bwkOs3Ec5FgNn6UcysEatLP5OYtsXTOzlkQ.webp" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">가수<div className="dot"></div>남성</dt>
                                            <dt className="name">지코</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>9</dl>
                                        <dl className="rankMark down">2</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://img1.newsis.com/2023/01/06/NISI20230106_0001170846_web.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">르세라핌</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                                <ul>
                                    <li className="rank">
                                        <dl>10</dl>
                                        <dl className="rankMark same">0</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb"><img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2022/11/29/479c1886-acb7-4416-963b-24baf1888c78.jpg" alt=""/></dl>
                                        <dl className="txt">
                                            <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                            <dt className="name">카라</dt>
                                        </dl>
                                    </li>
                                    <li className="score">4,669 점</li>
                                </ul>
                            </div>
                            <div className="btn">
                                <ul className="more">랭킹 더보기</ul>
                                <ul className="vote">투표하기</ul>
                            </div>
                        </div>
                    </div>
                    <div className="voteTitle">실시간 인기투표</div>
                    <div className="voteBox">
                        <Swiper
                            direction='vertical'
                            slidesPerView='auto'
                            spaceBetween={0}
                            touchReleaseOnEdges={true}
                            breakpoints={{
                                480: {
                                    direction: 'horizontal',
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    direction: 'horizontal',
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">아이돌 걸그룹 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202404/BF.36389450.1.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">아이돌 보이그룹 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://file2.nocutnews.co.kr/newsroom/image/2022/01/31/202201312047237692_0.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">트로트 가수 남성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="blank"></div>
                    <div className="voteTitle">투표율 급상승</div>
                    <div className="voteBox">
                        <Swiper
                            direction='vertical'
                            slidesPerView='auto'
                            spaceBetween={0}
                            touchReleaseOnEdges={true}
                            breakpoints={{
                                480: {
                                    direction: 'horizontal',
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    direction: 'horizontal',
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://img.tvreportcdn.de/cms-content/uploads/2024/05/10/55571fb3-142e-4439-be30-ed3a33f4ed3e.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">예능인 남성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.thetitlenews.net/news/photo/202309/2337_4003_554.png" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">예능인 여성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202407/ZA.36891140.1.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">배우 남성 랭킹</dl>
                                        <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                </div>

            </section>
        </>
    );
}

export default Youtuber;