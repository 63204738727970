import React, { useState } from 'react';
import WriteBox from "./writeBox";
import SideMenuBox from "./sideMenu";
import VoteBox from "./voteBox";

function CommentWrap() {
    // 상태를 관리하여 각 댓글의 dep2가 보여지는지 숨겨지는지 관리
    const [showDep2, setShowDep2] = useState({});

    // 클릭 이벤트 핸들러
    const handleReplyClick = (index) => {
        setShowDep2(prevState => ({
            ...prevState,
            [index]: !prevState[index]  // 현재 상태를 반전시킴
        }));
    };

    const commentReset = (index) => {
        console.log("resetActive");
    };

    // 댓글 데이터를 정의 (예시 데이터)
    const comments = [
        { id: 1, state: 'normal', name: '토끼', date: '2024.05.28 03:54:50', content: '어제 경기 봤는데 진짜 열심히 하시고 홈런 장면도 너무 멋있었습니다. 비록 결과는 조금 아쉬울 수 있지만 선수와 팀들의 노력이 정말 멋있습니다. 항상 응원합니다!!!', replies: [], good: 15, bad: 3 },
        { id: 2, state: 'block', name: '거북이', date: '2024.05.28 03:54:50', content: '신고로 숨김처리된 글입니다.', replies: [], good: 5, bad: 8 },
        { id: 3, state: 'normal', name: '거북이', date: '2024.05.28 03:54:50', content: '어제 경기 봤는데 진짜 열심히 하시고 홈런 장면도 너무 멋있었습니다. 비록 결과는 조금 아쉬울 수 있지만 선수와 팀들의 노력이 정말 멋있습니다. 항상 응원합니다!!!', replies: [
                { id: 11, state: 'normal', name: '거북이', date: '2024.05.28 03:54:50', content: '어제 경기 봤는데 진짜 열심히 하시고 홈런 장면도 너무 멋있었습니다. 비록 결과는 조금 아쉬울 수 있지만 선수와 팀들의 노력이 정말 멋있습니다. 항상 응원합니다!!!', good: 20, bad: 4 },
                { id: 12, state: 'normal', name: '거북이', date: '2024.05.28 03:54:50', content: '어제 경기 봤는데 진짜 열심히 하시고 홈런 장면도 너무 멋있었습니다. 비록 결과는 조금 아쉬울 수 있지만 선수와 팀들의 노력이 정말 멋있습니다. 항상 응원합니다!!!', good: 30, bad: 6 }
            ], good: 25, bad: 10 },
        { id: 4, state: 'normal', name: '거북이', date: '2024.05.28 03:54:50', content: '어제 경기 봤는데 진짜 열심히 하시고 홈런 장면도 너무 멋있었습니다. 비록 결과는 조금 아쉬울 수 있지만 선수와 팀들의 노력이 정말 멋있습니다. 항상 응원합니다!!!', replies: [], good: 12, bad: 2 }
    ];

    return (
        <>
            <div className="commentWrap">
                <div className="totalCnt">댓글 {comments.length}</div>
                <WriteBox newsId="1234567" />
                <div className="tab">
                    <ul className="on">추천순</ul>
                    <ul>최신순</ul>
                    <ul>답글순</ul>
                </div>
                <div className="commentList">
                    {comments.map((comment, index) => (
                        <ul key={comment.id} className="list">
                            <div className="dep1">
                                <li className="info">
                                    <dl className="name">{comment.name}</dl>
                                    <dl className="date">{comment.date}</dl>
                                </li>
                                <SideMenuBox isReset={commentReset} />
                                <li className={`cont ${comment.state === 'block' ? 'block' : ''}`}>{comment.content}</li>
                                <li className="reply" onClick={() => handleReplyClick(index)}>
                                    답글<span>{comment.replies.length}</span>
                                    <div className={`arw s16 ${showDep2[index] ? 'open' : ''}`}></div>
                                </li>
                                <VoteBox initialGood={comment.good} initialBad={comment.bad} />
                            </div>
                            {showDep2[index] && (
                                <div className="dep2">
                                    {comment.replies.length > 0 ? (
                                        comment.replies.map(reply => (
                                            <div className="inList" key={reply.id}>
                                                <li className="info">
                                                    <dl className="name">{reply.name}</dl>
                                                    <dl className="date">{reply.date}</dl>
                                                </li>
                                                <SideMenuBox isReset={commentReset} />
                                                <li className={`cont ${reply.state === 'block' ? 'block' : ''}`}>{reply.content}</li>
                                                <VoteBox initialGood={reply.good} initialBad={reply.bad} />
                                            </div>
                                        ))
                                    ) : (
                                        <div className="noReplies">등록된 답글이 없습니다.</div>
                                    )}
                                    <WriteBox newsId="1234567" />
                                    <div className="replyClose">
                                        <div className="in" onClick={() => handleReplyClick(index)}>답글 접기<div className="arw s16"></div></div>
                                    </div>
                                </div>
                            )}
                        </ul>
                    ))}
                </div>
                <div className="btnMore"><div className="in">댓글더보기<div className="arw"></div></div></div>
            </div>
        </>
    );
}

export default CommentWrap;
