import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authAtom';
import menu from './menu.js';
import TopPcRight from "./topPcRight";
import { useAppContext  } from '../../component/context';
import SearchSlide from "../../component/searchSlide";
import {initKakao} from "kakao-js-sdk";

function TopWrap() {
    const auth = useRecoilValue(authState); // 현재 인증 상태를 가져옵니다.
    const setAuthState = useSetRecoilState(authState); // 인증 상태를 업데이트할 수 있는 함수입니다.
    const navigate = useNavigate(); // 페이지 이동을 위한 navigate 훅입니다.
    const location = useLocation(); // 현재 URL 경로를 가져오기 위한 훅입니다.
    const currentPath = location.pathname;
    const [activeTab, setActiveTab] = useState(location.pathname);
    const { categoryList, kakaoApiKey } = useAppContext();
    initKakao(kakaoApiKey);

    useEffect(() => {
        // 현재 경로에 따라 초기 activeTab 설정
        if (location.pathname.startsWith('/news')) {
            setActiveTab('/news');
        } else if (location.pathname.startsWith('/ranking')) {
            setActiveTab('/ranking');
        } else {
            setActiveTab('/news'); // 기본적으로 /news를 활성화
        }
    }, [location.pathname]);

    const handleTabClick = (path) => {
        setActiveTab(path);
    };

    const { menuOpen, closeMenu, openMenu } = useAppContext ();

    const handleLinkClick = (path) => (event) => {
        event.preventDefault(); // 링크의 기본 동작을 방지
        closeMenu();
        navigate(path); // 페이지를 이동
    };

    // 로그아웃 처리 함수
    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('user');
        localStorage.removeItem('com.naver.nid.oauth.state_token');
        localStorage.removeItem('com.naver.nid.access_token');
        localStorage.removeItem('kakao_3dc1788d2fcbc63efea81a1facf87909');
        setAuthState({ isAuthenticated: false, token: null, user: null }); // Recoil 상태를 업데이트
        if (menuOpen) {
            closeMenu(); // 메뉴가 열려 있을 경우 닫기
        }
        navigate('/');
    };

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };

    const isNewsOrRankingPath = currentPath.startsWith('/news') || currentPath.startsWith('/ranking') || currentPath.startsWith('/vote');

    return (
        <>
            <section className="topWrap">
                <div className="naviDc">
                    <div className="navi">
                        <a href="https://www.dcinside.com/" target="_blank" rel="noreferrer"><ul className="openUrl">디시인사이드</ul></a>
                        <a href="https://gall.dcinside.com/" target="_blank" rel="noreferrer"><ul>갤러리</ul></a>
                        <a href="https://gall.dcinside.com/m" target="_blank" rel="noreferrer"><ul>마이너갤</ul></a>
                        <a href="https://gall.dcinside.com/n" target="_blank" rel="noreferrer"><ul>미니갤</ul></a>
                        <a href="https://gallog.dcinside.com/" target="_blank" rel="noreferrer"><ul>갤로그</ul></a>
                        <a href="https://event.dcinside.com/" target="_blank" rel="noreferrer"><ul>이벤트</ul></a>
                        <a href="https://mall.dcinside.com/?from=A08" target="_blank" rel="noreferrer"><ul>디시콘</ul></a>
                    </div>
                </div>
                <div className="top">
                    <Link to="/"><div className="logo"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" /></div></Link>
                    <TopPcRight />
                    <div className="mo" onClick={openMenu}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_menu.svg" alt="" /></div>
                </div>
                <div className="menuBox">
                    <ul className="dep1">
                        <li className="menu">
                            {menu.map((item, index) => (
                                <Link to={item.link} key={index}>
                                    <dl className={isActive(item.pathName) ? "on" : ""}>{item.title}</dl>
                                </Link>
                            ))}
                        </li>
                    </ul>
                    {isNewsOrRankingPath && (
                        <ul className="dep2">
                            <li className="menu">
                                <dl className="on">
                                    {isActive("/news") && (
                                        menu[0].subMenu.map((subItem, subIndex) => (
                                            <Link key={subIndex} to={subItem.link}>
                                                <dt className={isActive(subItem.pathName) ? "on" : ""}>
                                                    {subItem.title}
                                                </dt>
                                            </Link>
                                        ))
                                    )}
                                    {isActive("/ranking") && (
                                        menu[1].subMenu.map((subItem, subIndex) => (
                                            <Link key={subIndex} to={subItem.link}>
                                                <dt className={isActive(subItem.pathName) ? "on" : ""}>
                                                    {subItem.title}
                                                </dt>
                                            </Link>
                                        ))
                                    )}
                                    {isActive("/vote") && (
                                        menu[2].subMenu.map((subItem, subIndex) => (
                                            <Link key={subIndex} to={subItem.link}>
                                                <dt className={isActive(subItem.pathName) ? "on" : ""}>
                                                    {subItem.title}
                                                </dt>
                                            </Link>
                                        ))
                                    )}
                                </dl>
                            </li>
                        </ul>
                    )}
                </div>
            </section>
            <section className={`slideMenu ${menuOpen ? 'on' : ''}`}>
                <div className="top">
                    <a href="/" onClick={handleLinkClick('/')}><ul className="logo"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" /></ul></a>
                    <ul className="close" onClick={closeMenu}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close.svg" alt="" /></ul>
                </div>
                <div className="info">
                    <div className="login">
                        {auth.isAuthenticated ? (
                            <a href="/mypage" onClick={handleLinkClick('/mypage')}><div className="name">{auth.user?.name || ''}</div></a>
                        ) : (
                            <a href="/login" onClick={handleLinkClick('/login')}><div className="signIn">로그인 해주세요<div className="arw"></div></div></a>
                        )}
                    </div>
                    <div className="vote">
                        {auth.isAuthenticated ? (
                            <a href="/mypage" onClick={handleLinkClick('/mypage')}><div className="myVote">내 투표권<span>0개</span></div></a>
                        ) : null}
                        <a href="/mypage" onClick={handleLinkClick('/mypage')}><div className="btn"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg" alt="" />투표권 구매</div></a>
                    </div>
                </div>
                <div className="scroll">
                    <SearchSlide />
                    <div className="tab">
                        <ul
                            className={activeTab.startsWith('/news') ? "on" : ""}
                            onClick={() => handleTabClick('/news')}
                        >
                            뉴스
                        </ul>
                        <ul
                            className={activeTab.startsWith('/ranking') ? "on" : ""}
                            onClick={() => handleTabClick('/ranking')}
                        >
                            랭킹
                        </ul>
                    </div>
                    <div className={`menu news ${activeTab.startsWith('/news') ? 'on' : ''}`}>
                        <ul>
                            <li><a href="/news/trend" onClick={handleLinkClick('/news/trend')}>트렌드 뉴스</a></li>
                            <li>
                                <dl>
                                    <a href="/news/trend" onClick={handleLinkClick('/news/trend')}>전체</a>
                                </dl>
                                <dl>
                                    <a href="/news/trend/rank" onClick={handleLinkClick('/news/trend/rank')}>랭킹뉴스</a>
                                </dl>
                                {categoryList.map(item => (
                                    <dl key={item.id}>
                                    <a href={`/news/trend/${item.code}/1`} onClick={handleLinkClick(`/news/trend/${item.code}/1`)}>{item.name}</a>
                                    </dl>
                                ))}
                            </li>
                        </ul>
                        <ul>
                            <li><a href="/news/issue/1" onClick={handleLinkClick('/news/issue/1')}>디시이슈<div className="arw"></div></a></li>
                        </ul>
                        <ul>
                            <li><a href="/news/interview/1" onClick={handleLinkClick('/news/interview/1')}>디시인터뷰<div className="arw"></div></a></li>
                        </ul>
                    </div>
                    <div className={`menu rank ${activeTab.startsWith('/ranking') ? 'on' : ''}`}>
                        <ul>
                            <li><a href="/ranking/people/celebrity" onClick={handleLinkClick('/ranking/people/celebrity')}>연예인<div className="arw"></div></a></li>
                        </ul>
                        <ul>
                            <li><a href="/ranking/people/athlete" onClick={handleLinkClick('/ranking/people/athlete')}>스포츠인<div className="arw"></div></a></li>
                        </ul>
                        <ul>
                            <li><a href="/ranking/people/youtuber" onClick={handleLinkClick('/ranking/people/youtuber')}>유튜버<div className="arw"></div></a></li>
                        </ul>
                        <ul>
                            <li>투표 모아보기</li>
                            <li>
                                <dl><a href="/ranking/gather/all" onClick={handleLinkClick('/ranking/gather/all')}>전체</a></dl>
                                <dl><a href="/ranking/gather/celebrity" onClick={handleLinkClick('/ranking/gather/celebrity')}>연예인</a></dl>
                                <dl><a href="/ranking/gather/youtuber" onClick={handleLinkClick('/ranking/gather/youtuber')}>유튜버</a></dl>
                                <dl><a href="/ranking/gather/athlete" onClick={handleLinkClick('/ranking/gather/athlete')}>스포츠인</a></dl>
                            </li>
                        </ul>
                    </div>
                </div>
                {auth.isAuthenticated && (
                    <div className="logout">
                        <div className="btn" onClick={handleLogout}><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_logout.svg" alt="" />로그아웃</div>
                    </div>
                )}
            </section>
        </>
    );
}

export default TopWrap;
