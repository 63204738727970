import React, { useState } from 'react';

function VoteBox({ initialGood, initialBad }) {

    const [good, setGood] = useState(Number(initialGood));
    const [bad, setBad] = useState(Number(initialBad));

    const handleGoodClick = () => {
        setGood(good + 1);
    };

    const handleBadClick = () => {
        setBad(bad + 1);
    };

    return (
        <>
            <li className="vote">
                <dl className="good" onClick={handleGoodClick}><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_cmt_good.svg" alt=""/>{good}</dl>
                <dl className="bad" onClick={handleBadClick}><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_cmt_bad.svg" alt=""/>{bad}</dl>
            </li>
        </>

    );
}

export default VoteBox;
