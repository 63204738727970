import StickyDefault from "../layout/stickyDefault";
import React, {useEffect} from "react";
import ChartRank from "../../component/chartRank";
import ChartMini from "../../component/chartmini";
import {Link} from "react-router-dom";
import ChartKeyword from "../../component/chartKeyword";

function generateRandomData(size, min, max) {
    return Array.from({ length: size }, () => Math.floor(Math.random() * (max - min + 1)) + min);
}

const dataPoints = {
    labels: ['뉴진스', '에스파', 'IVE', 'QWER', '아일릿', 'G-IDLE', '아이유', '지코', '르세라핌', '카라'],
    datasets: [{
        data: [139000, 125000, 113000, 87500, 70500, 66000, 43000, 39500, 28000, 24000],
        borderWidth: 0,
        backgroundColor: '#2D65F2',
        barThickness: window.innerWidth < 480 ? 10 : 16,
        borderRadius: 50
    }]
};

const dataKeyword = [
    { x: '2024-06-29', y: 30000 },
    { x: '2024-06-30', y: 32000 },
    { x: '2024-07-01', y: 31000 },
    { x: '2024-07-02', y: 29000 },
    { x: '2024-07-03', y: 30000 },
    { x: '2024-07-04', y: 32000 },
    { x: '2024-07-05', y: 31000 },
    { x: '2024-07-06', y: 50000 },
    { x: '2024-07-07', y: 25000 },
    { x: '2024-07-08', y: 24000 },
    { x: '2024-07-09', y: 23000 },
    { x: '2024-07-10', y: 22000 },
    { x: '2024-07-11', y: 21000 },
    { x: '2024-07-12', y: 22000 },
    { x: '2024-07-13', y: 21000 },
    { x: '2024-07-14', y: 23000 },
    { x: '2024-07-15', y: 24000 },
    { x: '2024-07-16', y: 25000 },
    { x: '2024-07-17', y: 27000 },
    { x: '2024-07-18', y: 29000 },
    { x: '2024-07-19', y: 28000 },
    { x: '2024-07-20', y: 30000 },
    { x: '2024-07-21', y: 31000 },
    { x: '2024-07-22', y: 29000 },
    { x: '2024-07-23', y: 27000 },
    { x: '2024-07-24', y: 25000 },
    { x: '2024-07-25', y: 27000 },
    { x: '2024-07-26', y: 26000 },
    { x: '2024-07-27', y: 28000 },
    { x: '2024-07-28', y: 100000 }
];

const miniChartData = generateRandomData(30, 100, 1000);

function AwardsDetail() {

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="awardsDetail">
                    <div className="top">
                        <div className="stit">트렌드 어워즈</div>
                        <div className="title">아이돌 걸그룹 랭킹</div>
                        <div className="date">
                            <ul>투표기간</ul>
                            <ul>24306.14 ~ 24.06.30</ul>
                        </div>
                    </div>
                    <div className="top10">
                        <ul>
                            <li className="rank">1</li>
                            <li className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">뉴진스</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">2</li>
                            <li className="thumb"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">에스파</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">3</li>
                            <li className="thumb"><img src="https://img5.yna.co.kr/etc/inner/KR/2021/12/01/AKR20211201132800005_01_i_P2.jpg" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">IVE</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">4</li>
                            <li className="thumb"><img src="https://phinf.wevpstatic.net/MjAyMzEwMTNfOTcg/MDAxNjk3MTc4NTMyOTA2.YcfBd9JEgr4AG6ae5CxXQlHFZoyz0Gsbsd28r9gViZsg.ufxCBuSFybk-1d8KlB7a3WHGoQNB-JnN_OlsJC2nmUcg.JPEG/bfb0f355-9e7d-4c83-a030-814c6fe53c7b.jpeg?type=w670" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">QWER</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">5</li>
                            <li className="thumb"><img src="https://thumb.mtstarnews.com/06/2024/06/2024061908461769563_1.jpg" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">아일릿</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">6</li>
                            <li className="thumb"><img src="https://1.vikiplatform.com/pr/22068pr/4a9afa041c.jpg?x=b" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">G-IDLE</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">7</li>
                            <li className="thumb"><img src="https://image.genie.co.kr/Y/IMAGE/IMG_MUZICAT/IV2/Genie_Magazine/11927/Mgz_Main_Top_20220502111944.jpg/dims/resize/Q_80,0" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">르세라핌</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">8</li>
                            <li className="thumb"><img src="https://i.namu.wiki/i/j3UXEZwbSCRiuoYqx78X_IlqtbGsei844AJ7twEv0LplgNcb4rkchp0iTfa0e_UnVdB1lQyO9uZ0HGZmXWWmoQ.webp" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">KISS OF LIFE</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">9</li>
                            <li className="thumb"><img src="https://images.khan.co.kr/article/2023/11/18/news-p.v1.20231118.941944a5028e47559da978a174eb18ce.jpg" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">트리플에스</dl>
                            </li>
                        </ul>
                        <ul>
                            <li className="rank">10</li>
                            <li className="thumb"><img src="https://i.namu.wiki/i/STNZth6n2WTqfz67wqGifT6hfVJ5dPPC1U4EpKtaOstS7_Zv_cyVyVLysysd-AaBjQKuTLq5tKwrjOiWXruRxg.webp" alt=""/></li>
                            <li className="info">
                                <dl className="cate">아이돌 · 걸그룹</dl>
                                <dl className="name">베이비몬스터</dl>
                            </li>
                        </ul>
                    </div>
                    <div className="chartWrap">
                        <ChartRank dataPoints={dataPoints} />
                    </div>
                    <div className="chartTableRt">
                        <div className="chartTable addImg">
                            <ul className="tr head">
                                <li className="rank">순위</li>
                                <li className="thumb">이미지</li>
                                <li className="name"><div>이름</div></li>
                                <li className="cate">카테고리</li>
                                <li className="index">트랜드지수</li>
                                <li className="chart">지수 차트 (최근 30일)</li>
                                <li className="btnBox">투표하기</li>
                            </ul>
                            <ul className="tr">
                                <li className="rank"><div className="num">1</div><div className="rt1"><span>위</span> 뉴진스</div></li>
                                <li className="thumb">
                                    <div className="img"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></div>
                                </li>
                                <li className="name"><div>뉴진스</div></li>
                                <li className="cate"><div className="rt2">카테고리</div>가수</li>
                                <li className="index"><div className="rt2">트렌드지수</div>69,330</li>
                                <li className="chart"><div className="rt2">지수차트</div><div className="miniChart"><ChartMini data={miniChartData}/></div><div className="drop open"></div></li>
                                <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                            </ul>
                            <div className="detail">
                                <div className="layerBox">
                                    <div className="layerTitle"><span>1위</span>뉴진스</div>
                                    <div className="close"><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                                    <div className="scrollBox">
                                        <div className="inBox">
                                            <div className="left">
                                                <div className="tit">키워드</div>
                                                <div className="openChart"><ChartKeyword dataPoints={dataKeyword} /></div>
                                            </div>
                                            <div className="right">
                                                <div className="box">
                                                    <div className="tit">성별</div>
                                                    <div className="gender">
                                                        <dl className="male">
                                                            <dt>남성</dt>
                                                            <dt>44.34%</dt>
                                                        </dl>
                                                        <dl className="female">
                                                            <dt>여성</dt>
                                                            <dt>55.66%</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div className="box">
                                                    <div className="tit">연령</div>
                                                    <div className="age">
                                                        <dl>
                                                            <dt>10대</dt>
                                                            <dt>11%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>20대</dt>
                                                            <dt>11%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>30대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>40대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>50대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                        <dl>
                                                            <dt>60대</dt>
                                                            <dt>28%</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="tr">
                                <li className="rank"><div className="num">2</div><div className="rt1"><span>위</span> 에스파</div></li>
                                <li className="thumb">
                                    <div className="img"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></div>
                                </li>
                                <li className="name"><div>에스파</div></li>
                                <li className="cate"><div className="rt2">카테고리</div>가수</li>
                                <li className="index"><div className="rt2">트렌드지수</div>69,330</li>
                                <li className="chart"><div className="rt2">지수차트</div><div className="miniChart"><ChartMini data={miniChartData}/></div><div className="drop"></div></li>
                                <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                            </ul>
                            <ul className="tr">
                                <li className="rank"><div className="num">1000</div><div className="rt1"><span>위</span> IVE</div></li>
                                <li className="thumb">
                                    <div className="img"><img src="https://img5.yna.co.kr/etc/inner/KR/2021/12/01/AKR20211201132800005_01_i_P2.jpg" alt=""/></div>
                                </li>
                                <li className="name"><div>IVE</div></li>
                                <li className="cate"><div className="rt2">카테고리</div>가수</li>
                                <li className="index"><div className="rt2">트렌드지수</div>69,330</li>
                                <li className="chart"><div className="rt2">지수차트</div><div className="miniChart"><ChartMini data={miniChartData}/></div><div className="drop"></div></li>
                                <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AwardsDetail;