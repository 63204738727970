import StickyDefault from "../layout/stickyDefault";
import React, { useState } from 'react';
import {Link} from "react-router-dom";
import CustomSelect from "../../component/customSelect";

function RankAll() {

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [selectedOrder, setSelectedOrder] = useState('indexHigh');
    const [selectedPeriod, setSelectedPeriod] = useState('daily');
    const [selectedGender, setSelectedGender] = useState('none');

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="rankingMain">
                    <div className="mainTitle">
                        <ul className="tit">전체 랭킹</ul>
                        <ul className="update">24.04.08 오후 6시 5분</ul>
                        <ul className="info"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt=""/>랭킹은 종합지수를 반영하여 10분 간격으로 집계되며, 키워드의 온라인 관심도와 활동을 반영합니다.</ul>
                    </div>
                    <div className="rankSortWrap">
                        <CustomSelect
                            title="카테고리"
                            options={[
                                { label: '전체', value: 'all' },
                                { label: '연예인', value: 'celebrity' },
                                { label: '스포츠인', value: 'athlete' },
                                { label: '유튜버', value: 'youtuber' },
                            ]}
                            selectedValue={selectedCategory}
                            setSelectedValue={setSelectedCategory}
                        />
                        <CustomSelect
                            title="정렬순서"
                            options={[
                                { label: '종합지수 높은 순', value: 'indexHigh' },
                                { label: '랭킹 급상승 순', value: 'ranking' },
                                { label: '종합지수 낮은 순', value: 'indexLow' },
                            ]}
                            selectedValue={selectedOrder}
                            setSelectedValue={setSelectedOrder}
                        />
                        <CustomSelect
                            title="집계기간"
                            options={[
                                { label: '일일 랭킹', value: 'daily' },
                                { label: '주간 랭킹', value: 'weekly' },
                                { label: '월간 랭킹', value: 'monthly' },
                            ]}
                            selectedValue={selectedPeriod}
                            setSelectedValue={setSelectedPeriod}
                        />
                        <CustomSelect
                            title="성별 분류"
                            options={[
                                { label: '구분 없음', value: 'none' },
                                { label: '남성', value: 'male' },
                                { label: '여성', value: 'female' },
                            ]}
                            selectedValue={selectedGender}
                            setSelectedValue={setSelectedGender}
                        />
                    </div>
                    <div className="rankAllTable">
                        <ul className="head">
                            <li className="rank">랭킹</li>
                            <li className="thumb">이미지</li>
                            <li className="name">이름</li>
                            <li className="cate">카테고리</li>
                            <li className="gender">성별</li>
                            <li className="index">종합 지수</li>
                            <li className="btnBox">투표하기</li>
                        </ul>
                        <ul className="tr">
                            <li className="rank">
                                <dl className="num">1</dl>
                                <dl className="rankMark up">2</dl>
                            </li>
                            <li className="thumb"><div className="img"><img src="https://static.inews24.com/v1/d5c5474f70e948.jpg" alt=""/></div></li>
                            <li className="name">
                                <dl className="inCate">가수 · 남성</dl>
                                <dl className="inName">김민기</dl>
                                <dl className="inIndex"><div className="btn">7,280,743<div className="arw s16"></div></div></dl>
                            </li>
                            <li className="cate">가수</li>
                            <li className="gender">남성</li>
                            <li className="index"><div className="btn">7,280,743<div className="arw s16"></div></div></li>
                            <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                        </ul>
                        <ul className="tr">
                            <li className="rank">
                                <dl className="num">2</dl>
                                <dl className="rankMark down">1</dl>
                            </li>
                            <li className="thumb"><div className="img"><img src="https://img2.daumcdn.net/thumb/R658x0.q70/?fname=https://t1.daumcdn.net/news/202404/23/tvreport/20240423211236592vqgz.jpg" alt=""/></div></li>
                            <li className="name">
                                <dl className="inCate">가수 · 여성</dl>
                                <dl className="inName">셀린디온</dl>
                                <dl className="inIndex"><div className="btn">7,280,743<div className="arw s16"></div></div></dl>
                            </li>
                            <li className="cate">가수</li>
                            <li className="gender">여성</li>
                            <li className="index"><div className="btn">7,280,743<div className="arw s16"></div></div></li>
                            <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                        </ul>
                        <ul className="tr">
                            <li className="rank">
                                <dl className="num">3</dl>
                                <dl className="rankMark up">4</dl>
                            </li>
                            <li className="thumb"><div className="img"><img src="https://img.hankyung.com/photo/202004/PRU20200415040601848_P2.jpg" alt=""/></div></li>
                            <li className="name">
                                <dl className="inCate">가수 · 여성</dl>
                                <dl className="inName">레이디 가가</dl>
                                <dl className="inIndex"><div className="btn">7,280,743<div className="arw s16"></div></div></dl>
                            </li>
                            <li className="cate">가수</li>
                            <li className="gender">여성</li>
                            <li className="index"><div className="btn">7,280,743<div className="arw s16"></div></div></li>
                            <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                        </ul>
                        <ul className="tr">
                            <li className="rank">
                                <dl className="num">4</dl>
                                <dl className="rankMark same">0</dl>
                            </li>
                            <li className="thumb"><div className="img"><img src="https://cdn.hankyung.com/photo/202407/ZA.36891140.1.jpg" alt=""/></div></li>
                            <li className="name">
                                <dl className="inCate">배우 · 남성</dl>
                                <dl className="inName">변우석</dl>
                                <dl className="inIndex"><div className="btn">7,280,743<div className="arw s16"></div></div></dl>
                            </li>
                            <li className="cate">배우</li>
                            <li className="gender">남성</li>
                            <li className="index"><div className="btn">7,280,743<div className="arw s16"></div></div></li>
                            <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                        </ul>
                        <ul className="tr">
                            <li className="rank">
                                <dl className="num">5</dl>
                                <dl className="rankMark new">new</dl>
                            </li>
                            <li className="thumb"><div className="img"><img src="https://file2.nocutnews.co.kr/newsroom/image/2022/01/31/202201312047237692_0.jpg" alt=""/></div></li>
                            <li className="name">
                                <dl className="inCate">가수 · 남성</dl>
                                <dl className="inName">임영웅</dl>
                                <dl className="inIndex"><div className="btn">7,280,743<div className="arw s16"></div></div></dl>
                            </li>
                            <li className="cate">가수</li>
                            <li className="gender">남성</li>
                            <li className="index"><div className="btn">7,280,743<div className="arw s16"></div></div></li>
                            <li className="btnBox"><Link to="/vote/relation"><div className="btn">투표하기</div></Link></li>
                        </ul>
                    </div>
                    <div className="listLoader">
                        <div className="loader"></div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default RankAll;